import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Column,
  DatePickerCalendar,
  Loader,
  Paragraph,
  Row,
  Show,
  TimePickerIntervals,
} from '@wunder/ui-components';
import styles from '@/features/booking/assets/scss/components/BookingFlow/DateSelect.module.scss';
import {
  addDays,
  addMinutes,
  addMonths,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  max,
  min,
  NearestMinutes,
  roundToNearestMinutes,
  startOfDay,
  startOfMonth,
  subMinutes,
} from 'date-fns';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { useFormikContext } from 'formik';
import { BookingOverviewForm, StepRules } from '@/features/booking/types';
import {
  useRequestAvailableStationsMutation,
  useRequestBookingAvailabilityQuery,
} from '@/features/booking/services/Booking.service';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { useTypedSelector } from '@/core/redux/hooks';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';
import { useResponsive } from '@/core/hooks/useResponsive';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';
import { DateRange } from '@/core/types';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';
import Divider from '@/core/components/Divider';
import { VehicleCategoryTimeSlot } from '@/features/vehicles/types';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { useDelayedUpdate } from '@/core/hooks/useDelayedUpdate';
import { isEmpty } from 'lodash';
import { getMyTimezoneId } from '@/core/utils/getMyTimezoneId';
import { formatWithoutTZ } from '@/features/booking/utils/formatWithoutTZ';

const DateSelect: FC<
  Partial<BookingFlowIDs> & {
    rules?: StepRules['date'];
    numberOfMonths?: number;
    vehicleCategoryId?: string;
  }
> = ({ bookingId, branchId, rules, numberOfMonths = 2, vehicleCategoryId }) => {
  const activeFilters = useTypedSelector(getActiveFilterValues);
  const { values, setFieldValue, initialValues, validateForm } =
    useFormikContext<BookingOverviewForm>();
  const { isMobile } = useResponsive();
  const dateLocale = useDateLocale();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { data: termsAndConditions } = useRequestUserTermsAndConditionsStatusQuery(branchId!, {
    skip: !branchId,
  });

  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { data: branchData } = useRequestSingleBranchQuery(branchId!, { skip: !branchId });

  const { data: bookingAvailabilityDates, isFetching: isFetchingBookingAvailability } =
    useRequestBookingAvailabilityQuery(
      {
        branchId: branchId!,
        maxDateRange: bookingSettings?.value.properties.maxDateRange,
        vehicleAttributes: activeFilters?.vehicleAttributes,
        bookingId,
      },
      {
        skip:
          !branchData ||
          !bookingSettings ||
          !branchId ||
          termsAndConditions?.state !== 'ACCEPTED' ||
          !!vehicleCategoryId,
      },
    );

  const [
    fetchVehicleCategoryAvailability,
    { data: categoryAvailability, isLoading: isFetchingAvailableTimeSlots },
  ] = useRequestAvailableStationsMutation();

  const showTime = useDelayedUpdate(!!values.bookingDate?.from, 50, 0);

  useEffect(() => {
    // Only if vehicle category is present, we need to fetch availability for that category
    if (vehicleCategoryId) {
      fetchVehicleCategoryAvailability({
        stationId: +branchId!,
        vehicleCategoryId: +vehicleCategoryId,
        startTime: startOfMonth(values.selectedMonth ?? new Date()).toISOString(),
        endTime: endOfMonth(addMonths(values.selectedMonth ?? new Date(), 1)).toISOString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedMonth]);

  const vehicleCategoryTimeSlots = useMemo(() => {
    const availableTimeslots =
      categoryAvailability?.[0]?.availableCategories?.[0]?.availableTimeSlots;

    if (!availableTimeslots) {
      return null;
    }

    return availableTimeslots;
  }, [categoryAvailability]);

  const timeZone = useMemo(() => {
    return branchData?.timeZoneId ?? getMyTimezoneId();
  }, [branchData]);

  const toStationTimezone = useCallback(
    (date: string | Date) => {
      return toZonedTime(date, timeZone);
    },
    [timeZone],
  );

  const fetchingTimeslots = useDelayedUpdate(isFetchingAvailableTimeSlots, 0, 300);

  const unavailableDates = useMemo(() => {
    if (!branchId) {
      return [];
    }

    if (vehicleCategoryTimeSlots) {
      // We need to get all days in the range of available time slots so we can find the ones that are taken
      const rangeStart = startOfMonth(values.selectedMonth ?? toStationTimezone(new Date()));
      const rangeEnd = endOfMonth(
        addMonths(values.selectedMonth ?? toStationTimezone(new Date()), 1),
      );

      const allDaysInRange = eachDayOfInterval({
        start: rangeStart,
        end: rangeEnd,
      });

      // We need to get all available dates from the time slots
      const availableDates = vehicleCategoryTimeSlots.flatMap((slot) =>
        eachDayOfInterval({
          start: toStationTimezone(slot.startTime),
          end: toStationTimezone(slot.endTime),
        }).map((day) => format(day, 'yyyy-MM-dd')),
      );

      // We need to filter out the available dates from the range of all days
      return allDaysInRange
        .filter((date) => !availableDates.includes(format(date, 'yyyy-MM-dd')))
        .map((date) =>
          formatInTimeZone(
            startOfDay(formatWithoutTZ(mergeDateTime(date, '12:00'))),
            timeZone,
            "yyyy-MM-dd'T'HH:mm:ssXXX",
          ),
        ); // Use filter to exclude available dates
    }

    return (
      bookingAvailabilityDates
        ?.filter((booking) => booking.availableState === 'UNAVAILABLE')
        ?.map((booking) => booking.date) ?? []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingAvailabilityDates,
    branchId,
    vehicleCategoryTimeSlots,
    values.selectedMonth,
    toStationTimezone,
  ]);

  const findMatchingTimeSlots = (
    timeslots: VehicleCategoryTimeSlot[],
    bookingDate: BookingOverviewForm['bookingDate'],
    bookingTime?: BookingOverviewForm['bookingTime'],
  ) => {
    return timeslots.filter((slot) => {
      return (
        toStationTimezone(slot.startTime) <=
          (!isEmpty(bookingTime?.from)
            ? mergeDateTime(bookingDate!.from!, bookingTime!.from)
            : endOfDay(bookingDate!.from!)) &&
        toStationTimezone(slot.endTime) >=
          (!isEmpty(bookingTime?.to)
            ? mergeDateTime(bookingDate!.to! ?? bookingDate!.from, bookingTime!.to)
            : startOfDay(bookingDate!.to! ?? bookingDate!.from))
      );
    });
  };

  const matchingTimeslot = useMemo(() => {
    if (!vehicleCategoryTimeSlots || !values.bookingDate?.from) {
      return null;
    }

    const matchingTimeSlots = findMatchingTimeSlots(
      vehicleCategoryTimeSlots,
      values.bookingDate,
      values.bookingTime,
    );

    if (matchingTimeSlots.length) {
      return matchingTimeSlots;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bookingDate, values.bookingTime, vehicleCategoryTimeSlots, toStationTimezone]);

  const minDate = useMemo(() => {
    if (matchingTimeslot?.length) {
      const minimumAllowedStartTime = min(
        matchingTimeslot.map((slot) => toStationTimezone(slot.startTime)),
      );

      // We need to check if matching timeslot start time is at the start of month
      // If it is, we need to extend it to previous day so the "previous month" button is not disabled
      const startOfMonthDate = startOfMonth(minimumAllowedStartTime);

      if (isSameDay(minimumAllowedStartTime, startOfMonthDate)) {
        return format(
          subMinutes(startOfDay(minimumAllowedStartTime), 1),
          DateFormats.FULL_DATE_TIME,
        );
      }

      return format(minimumAllowedStartTime, DateFormats.FULL_DATE_TIME);
    }

    return rules?.minDate ?? format(toStationTimezone(new Date()), DateFormats.FULL_DATE_TIME);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules?.minDate, matchingTimeslot, toStationTimezone]);

  const maxDate = useMemo(() => {
    if (matchingTimeslot) {
      const maximumAllowedDuration = max(
        matchingTimeslot.map((slot) => toStationTimezone(slot.endTime)),
      );

      // We need to check if matching timeslot end time is at the end of month
      // If it is, we need to extend it to next day so the "next month" button is not disabled
      const endMonthDate = endOfMonth(toStationTimezone(maximumAllowedDuration));

      if (isSameDay(maximumAllowedDuration, endMonthDate)) {
        return format(addMinutes(endOfDay(maximumAllowedDuration), 1), DateFormats.FULL_DATE_TIME);
      }

      return format(maximumAllowedDuration, DateFormats.FULL_DATE_TIME);
    }

    return rules?.maxDate
      ? format(toStationTimezone(rules?.maxDate), DateFormats.FULL_DATE_TIME)
      : addDays(new Date(), bookingSettings?.value.properties.maxDateRange ?? 365).toString();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules?.maxDate, bookingSettings, matchingTimeslot, toStationTimezone]);

  const partiallyAvailableDates = useMemo(() => {
    if (!branchId) return [];

    if (vehicleCategoryTimeSlots) {
      const isAfterMidnight = (date: Date) => {
        const midnight = startOfDay(date);

        // We need to take into account now time, otherwise it would be seen as partially availabile if its after midnight
        if (isSameDay(date, toStationTimezone(new Date()))) {
          return isAfter(
            date,
            addMinutes(
              toStationTimezone(new Date()),
              bookingSettings?.value?.properties?.timeGap ?? 15,
            ),
          );
        }

        return isAfter(
          date,
          addMinutes(midnight, bookingSettings?.value?.properties?.timeGap ?? 15),
        );
      };

      const isBeforeMidnight = (date: Date) => {
        const midnight = endOfDay(date);
        return isBefore(
          date,
          subMinutes(midnight, bookingSettings?.value?.properties?.timeGap ?? 15),
        );
      };

      return vehicleCategoryTimeSlots.flatMap((slot) => [
        ...addToArrayConditionally(
          isAfterMidnight(toStationTimezone(slot.startTime)),
          toStationTimezone(slot.startTime),
        ),
        ...addToArrayConditionally(
          isBeforeMidnight(toStationTimezone(slot.endTime)),
          toStationTimezone(slot.endTime),
        ),
      ]);
    }

    return (
      bookingAvailabilityDates
        ?.filter((booking) => booking.availableState === 'PARTIALLY_AVAILABLE')
        ?.map((booking) => new Date(booking.date)) ?? []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingAvailabilityDates,
    branchId,
    vehicleCategoryTimeSlots,
    bookingSettings,
    toStationTimezone,
  ]);

  const shouldBeDisabled = useCallback(
    (value: string | null, datePeriod: 'from' | 'to') => {
      const { bookingTime, bookingDate } = values;

      // Early exit if any critical values are missing
      if (!value || !bookingDate) {
        return false;
      }

      // Determine the reference date based on the 'from' or 'to' period
      const dateReference =
        datePeriod === 'from'
          ? (bookingDate?.from ?? toStationTimezone(new Date()))
          : (bookingDate?.to ?? bookingDate?.from ?? toStationTimezone(new Date()));

      // Merge date and time
      const dateTime = mergeDateTime(dateReference, value);

      const minStartTime = max([toStationTimezone(new Date())]);

      const maxStartTime = min([
        ...addToArrayConditionally(
          !isEmpty(bookingTime?.to),
          mergeDateTime(bookingDate?.to! ?? bookingDate?.from, bookingTime?.to),
        ),
      ]);

      const minEndTime = max([
        mergeDateTime(bookingDate?.from ?? toStationTimezone(new Date()), bookingTime?.from),
        ...addToArrayConditionally(!!rules?.minDate, toStationTimezone(rules?.minDate!)),
      ]);
      const maxEndTime = min([
        ...addToArrayConditionally(!!rules?.maxDate, toStationTimezone(rules?.maxDate!)),
      ]);

      // If there are matching timeslots, check if the value fits within the allowed slots
      if (matchingTimeslot?.length) {
        const isWithinAnyAvailableTimeSlot = matchingTimeslot.some((slot) =>
          isWithinInterval(
            mergeDateTime(
              datePeriod === 'from' ? bookingDate.from! : (bookingDate.to ?? bookingDate.from!),
              value,
            ),
            {
              start: toStationTimezone(slot.startTime),
              end:
                datePeriod === 'from'
                  ? subMinutes(
                      toStationTimezone(slot.endTime),
                      bookingSettings?.value?.properties?.timeGap ?? 15,
                    )
                  : toStationTimezone(slot.endTime),
            },
          ),
        );

        return (
          !isWithinAnyAvailableTimeSlot ||
          (datePeriod === 'from'
            ? isBefore(dateTime, minStartTime) || isAfter(dateTime, maxStartTime)
            : isBefore(dateTime, minEndTime))
        );
      }

      if (datePeriod === 'from') {
        return (
          isBefore(dateTime, minStartTime) ||
          (isValid(maxStartTime)
            ? format(dateTime, DateFormats.FULL_DATE_TIME) ===
                format(maxStartTime, DateFormats.FULL_DATE_TIME) || isAfter(dateTime, maxStartTime)
            : false)
        );
      }

      return (
        isBefore(dateTime, minEndTime) ||
        format(dateTime, DateFormats.FULL_DATE_TIME) ===
          format(minEndTime, DateFormats.FULL_DATE_TIME) ||
        (isValid(maxEndTime) ? isAfter(dateTime, maxEndTime) : false)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, rules, toStationTimezone, matchingTimeslot],
  );

  const onDateChange = (range?: DateRange) => {
    const setFromTime = (time?: string) => {
      setTimeout(() => {
        void setFieldValue(
          'bookingTime.from',
          time
            ? format(
                roundToNearestMinutes(toStationTimezone(new Date(time)), {
                  nearestTo: (bookingSettings?.value?.properties?.timeGap ?? 15) as NearestMinutes,
                  roundingMethod: 'ceil',
                }),
                'HH:mm',
              )
            : undefined,
        );
      }, 10);
    };

    const setEndTime = (time?: string) => {
      setTimeout(() => {
        void setFieldValue(
          'bookingTime.to',
          time
            ? format(
                roundToNearestMinutes(toStationTimezone(new Date(time)), {
                  nearestTo: (bookingSettings?.value?.properties?.timeGap ?? 15) as NearestMinutes,
                  roundingMethod: 'floor',
                }),
                'HH:mm',
              )
            : undefined,
        );
      }, 10);
    };

    // If vehicle category is present, it means it should use timeslots available for that category
    // And based on date selection we need to prefill time values here
    if (vehicleCategoryId && vehicleCategoryTimeSlots && range) {
      const timeslots = findMatchingTimeSlots(vehicleCategoryTimeSlots, range);

      if (timeslots?.length === 1) {
        const matchedTimeSlot = timeslots[0];

        // Depending on different cases we need to set the time values
        if (isSameDay(range.from, toStationTimezone(matchedTimeSlot.startTime))) {
          // When start time matches the matchedTimeslot startTime, immediately set the time
          setFromTime(matchedTimeSlot.startTime);

          if (
            // If end time is within the same day as the matched timeslot end time
            // NOTE: we check from as well because user can select the same day as the end time
            isSameDay(range.from, toStationTimezone(matchedTimeSlot.endTime)) ||
            isSameDay(range.to, toStationTimezone(matchedTimeSlot.endTime))
          ) {
            setEndTime(matchedTimeSlot.endTime);
            // Validate form necessary because button remains disabled when we fill both times
            setTimeout(() => {
              void validateForm();
            }, 100);
          } else {
            // Fallback to no end time, user has to select it
            setEndTime();
          }
          // In case end time only matches the timeslot end time, set immediately the end time
        } else if (isSameDay(range.to ?? range.from, toStationTimezone(matchedTimeSlot.endTime))) {
          setFromTime();
          setEndTime(matchedTimeSlot.endTime);
        } else {
          // Fallback to nothing set
          setFromTime();
          setEndTime();
        }
      } else {
        // If we have multiple timeslots founds, fallback to no time set, user has to select it
        setFromTime();
        setEndTime();
      }
    }

    if (rules?.preventSelectionCancel) {
      if (
        isBefore(
          mergeDateTime((range?.to ?? initialValues?.bookingDate?.to)!, values.bookingTime?.to),
          initialValues.bookingDate?.to!,
        )
      ) {
        void setFieldValue('bookingTime.to', initialValues?.bookingTime?.to);
      }

      // This is an edge case where user is selecting current date, he should be able to go back to it
      if (!range) {
        void setFieldValue('bookingDate', {
          from: initialValues?.bookingDate?.from,
          to: initialValues?.bookingDate?.to,
        });

        return;
      }

      // This means that date range cannot be cleared, mainly used when extending booking
      // We dont want to allow the user the clear the range of booking that is already there, just extend it
      if (!range?.to) {
        void setFieldValue('bookingDate', {
          from: values?.bookingDate?.from,
          to: values?.bookingDate?.to,
        });

        return;
      }
    }

    if (range) {
      // We need to include time in these selected dates
      void setFieldValue('bookingDate', {
        from: mergeDateTime(range?.from, values.bookingTime?.from),
        to: range?.to && mergeDateTime(range?.to, values.bookingTime?.to),
      });
    }
  };

  useEffect(() => {
    // We dont need this check since vehicle categories work with timeslots
    if (vehicleCategoryId) return;

    const defaultStartTime = formatInitialTime({
      initialTime: bookingSettings?.value?.properties?.preselectedStartingTime,
      buffer: 60,
      roundTo: bookingSettings?.value?.properties?.timeGap,
      timezone: branchData?.timeZoneId,
    });

    const defaultEndTime = formatInitialTime({
      initialTime: bookingSettings?.value?.properties?.preselectedEndingTime,
      buffer: 120,
      roundTo: bookingSettings?.value?.properties?.timeGap,
      timezone: branchData?.timeZoneId,
    });

    if (!values?.bookingDate?.from) {
      void setFieldValue('bookingTime.from', defaultStartTime);
      void setFieldValue('bookingTime.to', defaultEndTime);
      return;
    }

    // We need to resolve cases where future day is selected as well as time, and changing date in the past would make time invalid, so we need to reset it
    if (
      values.bookingDate.from !== initialValues.bookingDate?.from &&
      values.bookingTime?.from &&
      shouldBeDisabled(values.bookingTime?.from, 'from') &&
      !rules?.disableStartTimeSelection
    ) {
      void setFieldValue('bookingTime.from', defaultStartTime);

      return;
    }

    if (rules?.maxDate && values.bookingDate.to) {
      const maximumDate = toStationTimezone(rules.maxDate);

      if (isAfter(values.bookingDate.to, maximumDate)) {
        void setFieldValue('bookingDate.to', maximumDate);
        void setFieldValue('bookingTime.to', format(maximumDate, 'HH:mm'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bookingDate]);

  return (
    <div ref={containerRef} className={styles['date-select']}>
      {isFetchingBookingAvailability && <Loader cover />}

      <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
        <Column sm={12} md={6}>
          <Paragraph marginBottom={10} size={2}>
            <Trans id="bookings.filters.selectDate">Select date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} size={4} textColor="text-400">
            {values.bookingDate?.from ? (
              `${format(values.bookingDate?.from, DateFormats.SHORT_DATE, {
                locale: dateLocale,
              })} - ${format(
                values.bookingDate?.to ?? values.bookingDate?.from,
                DateFormats.SHORT_DATE,
                { locale: dateLocale },
              )}`
            ) : (
              <Trans id="bookings.filters.noDateSelected">No date selected</Trans>
            )}
          </Paragraph>
        </Column>
      </Row>
      <div className={styles['date-select__calendar']}>
        {fetchingTimeslots && <Loader width={25} height={25} cover />}
        <DatePickerCalendar
          fieldProps={{
            isRange: true,
            initialMonth: values.selectedMonth,
            allowableSelectionRange: {
              minDate,
              maxDate,
            },
            onMonthChange: (month) => {
              void setFieldValue('selectedMonth', month);
            },
            onChange: (dateRange) => onDateChange(dateRange as DateRange | undefined),
            disabledDates: unavailableDates,
            markedDates: partiallyAvailableDates,
            markedDateColor: 'var(--y-400)',
            numberOfMonths: isMobile ? 1 : numberOfMonths,
          }}
          name="bookingDate"
        />
      </div>

      <Show when={showTime}>
        <Divider />

        <Row
          className={styles['date-select__time-picker']}
          alignItems="center"
          justify="space-between"
          gapSm="xs"
        >
          <Column sm={12} md={2} lg={3}>
            <Paragraph marginBottom={0}>
              <Trans id="booking.filters.selectTime">Select time</Trans>
            </Paragraph>
          </Column>

          <Column sm={6} md={4} lg={4}>
            <TimePickerIntervals
              fieldAttr={{
                id: 'from',
                required: true,
                disabled: rules?.disableStartTimeSelection,
                placeholder: t({ id: 'bookings.filters.startTime', message: 'Start' }),
              }}
              fieldProps={{
                clearable: true,
                size: 'small',
                timeInterval: (bookingSettings?.value?.properties?.timeGap as number) ?? 30,
                isDisabled: (val) => shouldBeDisabled(val!, 'from'),
                onChange: (selectedTime) => {
                  void setFieldValue(
                    'bookingDate.from',
                    mergeDateTime(values.bookingDate?.from!, selectedTime),
                  );
                },
                boundingBox: containerRef,
              }}
              name="bookingTime.from"
            />
          </Column>

          <Column sm={6} md={4} lg={4}>
            <TimePickerIntervals
              fieldAttr={{
                id: 'to',
                required: true,
                placeholder: t({ id: 'bookings.filters.endTime', message: 'End' }),
              }}
              fieldProps={{
                clearable: true,
                size: 'small',
                timeInterval: (bookingSettings?.value?.properties?.timeGap as number) ?? 30,
                isDisabled: (val) => {
                  return shouldBeDisabled(val!, 'to');
                },
                onChange: (selectedTime) => {
                  void setFieldValue(
                    'bookingDate.to',
                    mergeDateTime(
                      values.bookingDate?.to ?? values.bookingDate?.from!,
                      selectedTime,
                    ),
                  );
                },
                boundingBox: containerRef,
              }}
              name="bookingTime.to"
            />
          </Column>
        </Row>
      </Show>
    </div>
  );
};

export default DateSelect;
