import React from 'react';
import FullScreen from '@/core/components/Layouts/FullScreen';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import BusinessAccountJoinWidget from '@/features/businessAccounts/components/BusinessAccountJoinWidget';
import businessAccountCreateStyles from '@/core/assets/scss/pages/BusinessAccountCreate.module.scss';
import {
  useRequestBusinessAccountMembershipQuery,
  useRequestJoinBusinessAccountRequestsQuery,
} from '@/features/businessAccounts/services/BusinessAccount.service';
import { Button, Heading, Loader, Paragraph } from '@wunder/ui-components';
import { useNavigate } from 'react-router';
import useFeatureCheck from '@/features/auth/hooks/useFeatureCheck';
import { TenantFeatures } from '@/features/auth/enums';
import useRequestUserSignOut from '@/features/auth/hooks/useRequestUserSignOut';

const Create = () => {
  const navigate = useNavigate();
  const hasFeature = useFeatureCheck();
  const { data: businessAccountJoinRequested, isLoading: isLoadingJoinRequests } =
    useRequestJoinBusinessAccountRequestsQuery();
  const onSignOutClick = useRequestUserSignOut();

  const { data: businessAccountMember, isLoading: isLoadingBusinessAccountMembership } =
    useRequestBusinessAccountMembershipQuery();

  const hasPendingJoinRequests = !!businessAccountJoinRequested?.length;
  const isMemberOfAnotherBusinessAccount =
    businessAccountMember && businessAccountMember?.length > 0;

  return (
    <FullScreen
      mainSize="narrow"
      actions={
        <Button
          variation="secondary"
          onClick={() => {
            if (hasFeature(TenantFeatures.ENDUSER_WEB_APP)) {
              return navigate('/booking');
            }

            return onSignOutClick();
          }}
        >
          {hasFeature(TenantFeatures.ENDUSER_WEB_APP) ? (
            <Trans id="general.close">Close</Trans>
          ) : (
            <Trans id="header.dropdown.logout">Logout</Trans>
          )}
        </Button>
      }
      title={t({ id: 'businessAccounts.create.title', message: 'Business account setup' })}
      contentClassName={businessAccountCreateStyles['business-account-create']}
      main={
        <div className={businessAccountCreateStyles['business-account-create__content']}>
          {(isLoadingJoinRequests || isLoadingBusinessAccountMembership) && <Loader />}

          {isMemberOfAnotherBusinessAccount && (
            <>
              <Heading align="center" size={4}>
                <Trans id="general.notAllowed">Not allowed</Trans>
              </Heading>
              <Paragraph align="center" textColor="text-400" size={4} marginBottom={24}>
                <Trans id="businessAccount.create.alreadyMember">
                  Sorry, this action is not allowed. You are already a member of another business
                  account.
                </Trans>
              </Paragraph>
            </>
          )}

          {!isLoadingBusinessAccountMembership &&
            !isLoadingJoinRequests &&
            !isMemberOfAnotherBusinessAccount && (
              <>
                {hasPendingJoinRequests && (
                  <>
                    <Heading align="center" size={4}>
                      <Trans id="businessAccount.create.joinRequestPending">
                        Join request pending
                      </Trans>
                    </Heading>
                    <Paragraph align="center" textColor="text-400" size={4} marginBottom={24}>
                      <Trans id="businessAccount.create.joinRequestPendingDescription">
                        Your join request is pending and needs to be approved by business account
                        owner. Come back later.
                      </Trans>
                    </Paragraph>
                  </>
                )}{' '}
                <BusinessAccountJoinWidget />
              </>
            )}
        </div>
      }
    />
  );
};

export default Create;
