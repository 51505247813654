import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';
import { Loader } from '@wunder/ui-components';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import { useTypedSelector } from '@/core/redux/hooks';
import { getAllStepsCompleted } from '@/features/account/redux/account.selectors';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { Trans } from '@lingui/react/macro';

const Account: FC = () => {
  const location = useLocation();
  const { data: meData, isError: errorFetchingMeData } = useRequestMeDataQuery();
  const {
    isFetching: fetchingBranchesData,
    data: resolvedBranch,
    isError: errorFetchingBranchResolver,
  } = useRequestResolvedBranchQuery();
  const allStepsCompleted = useTypedSelector(getAllStepsCompleted);

  if (errorFetchingMeData || errorFetchingBranchResolver) {
    return (
      <ErrorFallback
        message={
          <Trans id="errorFallback.dataFailedToLoad">Data failed to load. Try again later.</Trans>
        }
      />
    );
  }

  if (!meData || (fetchingBranchesData && !resolvedBranch?.id)) {
    return <Loader cover />;
  }

  if (
    location.state?.fromLogin &&
    (meData?.customProperties?.accountSetupSkipped || allStepsCompleted)
  ) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default Account;
