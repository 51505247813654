import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import {
  AvailableBookingStationArgs,
  AvailableBookingStations,
  BookingAvailability,
  BookingAvailabilityArgs,
  BookingCreationArgs,
  Booking,
  BookingExtended,
  BookingArgs,
  BookingUpdateArgs,
  BookingExtendStatus,
  DateFilterRange,
  BookingEstimatedPricing,
  BookingEstimatedPricingArgs,
} from '@/features/booking/types';
import {
  addDays,
  eachDayOfInterval,
  eachWeekendOfInterval,
  endOfDay,
  endOfWeek,
  isSameWeek,
  isWeekend,
  startOfWeek,
} from 'date-fns';
import { BookingCacheTags } from '@/features/booking/enums';
import { stringify } from 'qs';
import { formatResponse } from '@/core/api/formatAPIResponse';
import { TransformedResponse } from '@/core/types';
import { NotificationStore } from '@wunder/ui-components';
import { t } from '@lingui/core/macro';
import { omit } from 'lodash';
import { formatWithoutTZ } from '../utils/formatWithoutTZ';

const formatFlexibleDateRange = (values: Partial<AvailableBookingStationArgs>) => {
  switch (values.flexibleDurationType) {
    case 'WEEKEND':
      return eachWeekendOfInterval({
        start: values?.startTime ? new Date(values.startTime) : new Date(),
        end: values?.endTime ? new Date(values?.endTime) : addDays(new Date(), 15),
      }).reduce((acc, weekend) => {
        // Start of the Saturday for the weekend
        const startOfSaturday = startOfWeek(weekend, { weekStartsOn: 6 }); // Saturday start
        const endOfSunday = endOfDay(addDays(startOfSaturday, 1)); // End of Sunday (23:59:59)

        // Check if the previous weekend already included this weekend's Saturday
        if (
          !acc.some((range) => new Date(range.startTime).getTime() === startOfSaturday.getTime())
        ) {
          acc.push({
            startTime: formatWithoutTZ(startOfSaturday),
            endTime: formatWithoutTZ(endOfSunday),
          });
        }

        return acc;
      }, [] as DateFilterRange[]);
    case 'WEEK':
      return eachDayOfInterval({
        start: values?.startTime ? new Date(values?.startTime) : new Date(),
        end: values?.endTime ? new Date(values?.endTime) : addDays(new Date(), 15),
      })
        .filter((day) => !isWeekend(day))
        .reduce((acc, date) => {
          if (!acc.some((range) => isSameWeek(range.startTime, date))) {
            acc.push({
              startTime: formatWithoutTZ(date),
              endTime: formatWithoutTZ(endOfWeek(date, { weekStartsOn: 6 })), // Sunday at 23:59
            });
          }

          return acc;
        }, [] as DateFilterRange[]);
    default:
      return null;
  }
};

export const BookingService = createApi({
  reducerPath: 'api-bookings',
  baseQuery: RestClientWrapper,
  tagTypes: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
  endpoints: (builder) => ({
    requestBookingAvailability: builder.query<BookingAvailability[], BookingAvailabilityArgs>({
      query: ({ branchId, maxDateRange = 365, vehicleAttributes, bookingId }) => {
        const currentDate = new Date();

        return {
          url: `/front/bookings/availability`,
          requestParams: {
            paramsSerializer: {
              serialize: (param) => stringify(param, { encode: true, arrayFormat: 'comma' }),
            },
            params: {
              branchId,
              includeWithPricingOnly: true,
              startDate: currentDate.toISOString(),
              endDate: addDays(currentDate, maxDateRange).toISOString(),
              vehicleAttributes,
              excludeBookingId: bookingId,
            },
          },
        };
      },
      providesTags: [BookingCacheTags.BOOKING_AVAILABILITY],
    }),

    requestMyTrips: builder.query<TransformedResponse<Booking[]>, BookingArgs>({
      query: (params) => ({
        url: '/front/bookings',
        requestParams: {
          params: {
            ...params,
            size: 10,
          },
        },
      }),
      transformResponse: formatResponse,
      providesTags: [BookingCacheTags.MY_TRIPS],
    }),

    requestBookingById: builder.query<BookingExtended, { id: number; userGroupId?: number }>({
      query: ({ id, userGroupId }) => ({
        url: `/front/bookings/${id}`,
        requestParams: {
          params: {
            userGroupId,
          },
        },
      }),
      providesTags: (data) => [{ type: BookingCacheTags.MY_TRIPS, id: data?.id }],
    }),

    requestBookingCreate: builder.mutation<BookingExtended, BookingCreationArgs>({
      query: (data) => ({
        url: '/front/bookings',
        requestParams: { method: 'post', data },
      }),
      invalidatesTags: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
    }),

    requestBookingUpdate: builder.mutation<BookingExtended, BookingUpdateArgs>({
      query: (data) => ({
        url: `/front/bookings/${data.id}`,
        requestParams: { method: 'patch', data },
      }),
      invalidatesTags: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
    }),

    requestBookingCancel: builder.mutation<void, number>({
      query: (bookingId) => ({
        url: `/front/bookings/${bookingId}`,
        requestParams: { method: 'DELETE' },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'booking.notification.cancelSuccess',
                message: 'Booking successfully canceled.',
              }),
            });
          },
        },
      }),
      invalidatesTags: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
    }),

    requestAvailableStations: builder.mutation<
      AvailableBookingStations[],
      Partial<AvailableBookingStationArgs>
    >({
      query: (args) => {
        const specificStartTime =
          args.startTime && args.endTime
            ? [
                {
                  startTime: formatWithoutTZ(new Date(args.startTime)),
                  endTime: formatWithoutTZ(new Date(args.endTime)),
                },
              ]
            : [];

        const flexibleDateRange = formatFlexibleDateRange(args);

        return {
          url: `/front/bookings/available-stations`,
          requestParams: {
            method: 'POST',
            data: {
              ...omit(args, ['startTime', 'endTime', 'flexibleDurationType']),
              timeSlots: flexibleDateRange ?? specificStartTime,
            },
          },
        };
      },
    }),

    requestCanExtendBooking: builder.query<BookingExtendStatus, number>({
      query: (id) => {
        return {
          url: `/front/bookings/${id}/extendable`,
        };
      },
      keepUnusedDataFor: 0,
    }),

    requestBookingEstimatedPricing: builder.query<
      BookingEstimatedPricing,
      BookingEstimatedPricingArgs
    >({
      query: (args) => {
        const additions = args.additions?.join(',') ?? undefined;

        return {
          url: `/front/booking-price-calculator`,
          requestParams: {
            params: {
              ...args,
              additions,
            },
          },
        };
      },
    }),
  }),
});

export const {
  useRequestBookingAvailabilityQuery,
  useRequestMyTripsQuery,
  useRequestCanExtendBookingQuery,
  useRequestBookingByIdQuery,
  useLazyRequestBookingByIdQuery,
  useRequestBookingCreateMutation,
  useRequestBookingUpdateMutation,
  useRequestBookingCancelMutation,
  useRequestAvailableStationsMutation,
  useRequestBookingEstimatedPricingQuery,
} = BookingService;
