import React, { FC, useMemo, useState } from 'react';
import {
  Checkbox,
  DropdownWrapper,
  Loader,
  Paragraph,
  RadioGroup,
  Row,
  Switch,
  Text,
} from '@wunder/ui-components';
import { plural, t } from '@lingui/core/macro';
import { getNumberOfActiveFilters } from '@/features/booking/utils/getNumberOfActiveFilters';
import styles from '@/features/booking/assets/scss/components/Filters/VehicleAttributesFilter.module.scss';
import { useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';
import { useRequestVehicleAttributesQuery } from '@/features/vehicles/services/Vehicles.service';
import { VehicleAttributes } from '@/features/vehicles/types';
import { toUpper } from 'lodash';
import { GROUP_ITEM_SUFFIX } from './FiltersForm';

interface VehicleAttributesFilterT {
  isReadOnly?: boolean;
}

interface AttributeFilterGroupT {
  vehicleAttributeConfig: VehicleAttributes;
}

/**
 * 'name' properties on all of these must have prefixes because the properties are numbers and formik converts all values to arrays
 * rather then object with dot notation
 */
const AttributeFilterGroup: FC<AttributeFilterGroupT> = ({ vehicleAttributeConfig }) => {
  return (
    <div className={styles['vehicle-attributes-filter__group']}>
      {vehicleAttributeConfig.selectionType !== 'SWITCH' && (
        <Paragraph size={3} textColor="text-400">
          {toUpper(vehicleAttributeConfig.name)}
        </Paragraph>
      )}

      {vehicleAttributeConfig.selectionType === 'CHECKBOX' &&
        vehicleAttributeConfig.attributes.map((checkboxAttr) => {
          return (
            <Row key={checkboxAttr.id} marginBottom={8}>
              <Checkbox
                name={`vehicleAttributes.${GROUP_ITEM_SUFFIX}${checkboxAttr.id}`}
                fieldAttr={{ id: `vehicleAttributes.${checkboxAttr.id}` }}
                fieldProps={{ label: checkboxAttr.name }}
              />
            </Row>
          );
        })}

      {vehicleAttributeConfig.selectionType === 'RADIO' && (
        <RadioGroup
          name="vehicleAttributes"
          direction="column"
          radioOptions={vehicleAttributeConfig.attributes.map(({ name, id }) => ({
            label: name,
            value: `${GROUP_ITEM_SUFFIX}${id}`,
          }))}
        />
      )}

      {vehicleAttributeConfig.selectionType === 'SWITCH' &&
        vehicleAttributeConfig.attributes.map(({ name, id }) => (
          <Switch
            key={id}
            fieldAttr={{
              id: name,
            }}
            fieldProps={{ label: name, style: 'stretch' }}
            name={`vehicleAttributes[${GROUP_ITEM_SUFFIX}${id}]`}
          />
        ))}
    </div>
  );
};

const AttributesFilters = () => {
  const { data: vehicleAttributesAPI, isFetching } = useRequestVehicleAttributesQuery();

  const sortedAttributes = useMemo(() => {
    if (!vehicleAttributesAPI) {
      return [];
    }

    return [...vehicleAttributesAPI].sort((a, b) => a.order - b.order);
  }, [vehicleAttributesAPI]);

  return (
    <div className={styles['vehicle-attributes-filter__container']}>
      {isFetching ? (
        <Loader cover />
      ) : (
        sortedAttributes.map((vehicleAttribute) => (
          <AttributeFilterGroup
            key={vehicleAttribute.id}
            vehicleAttributeConfig={vehicleAttribute}
          />
        ))
      )}
    </div>
  );
};

const VehicleAttributesFilter: FC<VehicleAttributesFilterT> = ({ isReadOnly }) => {
  const { setFieldValue, values } = useFormikContext<BookingFiltersForm>();
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean>(false);

  return (
    <DropdownWrapper
      triggerElement={
        <Text
          name="vehicleFilters"
          fieldProps={{
            clearable: !isReadOnly,
            onClear: () => {
              void setFieldValue('vehicleFilters', undefined);
              void setFieldValue('vehicleAttributes', undefined);
            },
            label: '',
            iconLeft: { icon: 'filters', color: 'icon-400' },
            borderless: true,
          }}
          fieldAttr={{
            id: 'vehicleFilters',
            placeholder: t({
              id: 'bookings.filters.allVehicles',
              message: 'All vehicles',
            }),
            onFocus: () => setFilterDropdownVisible(true),
            readOnly: true,
          }}
        />
      }
      onRequestClose={() => {
        if (filterDropdownVisible) {
          setFilterDropdownVisible(false);
          const activeFilterNumber = getNumberOfActiveFilters(values.vehicleAttributes);
          void setFieldValue(
            'vehicleFilters',
            activeFilterNumber
              ? t({
                  id: 'bookings.filters.activeFilterNumber',
                  message: plural(activeFilterNumber, {
                    one: '# Filter',
                    other: '# Filters',
                  }),
                })
              : undefined,
          );
        }
      }}
      dropdownContainerClassName={styles['vehicle-attributes-filter']}
      dropdownContent={<AttributesFilters />}
      isVisible={!isReadOnly && filterDropdownVisible}
    />
  );
};

export default VehicleAttributesFilter;
