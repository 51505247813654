import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux';
import { Provider as ReduxProvider } from 'react-redux';
import {
  WunderStyleguide,
  NotificationProvider,
  WunderConfigProvider,
} from '@wunder/ui-components';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import ErrorHandlerProvider from '@/core/components/ErrorHandlers/ErrorHandlingProvider';
import Router from '@/core/pages/Routers/Router';
import { ErrorBoundary } from '@sentry/react';
import TenantInfoResolver from '@/features/auth/components/TenantInfoResolver';
import TranslationProvider from '@/core/components/TranslationProvider';
import { ModalProvider } from '@/core/components/UI/modals/ModalProvider';

const App = () => {
  return (
    <ReduxProvider store={store}>
      {/* Load CSS Variables from @wunder/ui-components */}
      <WunderStyleguide />
      <TenantInfoResolver>
        <WunderConfigProvider>
          <TranslationProvider>
            {/* Sentry to report crash errors */}
            <ErrorBoundary fallback={<ErrorFallback />}>
              <PersistGate persistor={persistor}>
                {/* Initialize Notification Provider to be able to display notifications in the app */}
                <NotificationProvider />
                {/* Initialize ErrorHandlerProvider to be able to display errors from backend */}
                <ErrorHandlerProvider />
                <ModalProvider>
                  <Router />
                </ModalProvider>
              </PersistGate>
            </ErrorBoundary>
          </TranslationProvider>
        </WunderConfigProvider>
      </TenantInfoResolver>
    </ReduxProvider>
  );
};

export default App;
