import React, { FC, useState, memo } from 'react';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import styles from '../../../../assets/scss/components/BookingFlow/BookingSummary.module.scss';
import { Button, Column, Heading, Loader, Paragraph, Row, Separator } from '@wunder/ui-components';
import {
  useRequestResolvedBranchQuery,
  useRequestSingleBranchQuery,
} from '@/features/branches/services/Branches.service';
import { format } from 'date-fns';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import PricingBreakDown from './PricingBreakdown';
import { Trans } from '@lingui/react/macro';
import { useRequestPaymentSourcesQuery } from '@/features/payment/services/Payment.service';
import LocationGuidelinesModal from '@/features/booking/components/LocationGuidelinesModal';
import { getPersonalPaymentName } from '@/features/booking/utils/getPersonalPaymentName';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';
import {
  useRequestVehicleAdditionsQuery,
  useRequestVehicleCategorySingleQuery,
} from '@/features/vehicles/services/Vehicles.service';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';

const BookingSummary: FC<Pick<BookingFlowIDs, 'branchId'>> = ({ branchId }) => {
  const { values } = useFormikContext<BookingOverviewForm>();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: personalPayments } = useRequestPaymentSourcesQuery({
    branchId: resolvedBranch!.id,
  });
  const [guidelinesModal, setGuidelinesModal] = useState(false);

  const { data: vehicleCategory } = useRequestVehicleCategorySingleQuery(
    values.selectedVehicleCategory!,
  );

  const { isFetching: isFetchingAdditions } = useRequestVehicleAdditionsQuery({
    branchId,
    startDate: values.bookingDate?.from!.toISOString()!,
    endDate: values.bookingDate?.to
      ? values.bookingDate?.to.toISOString()
      : values.bookingDate?.from!.toISOString()!,
    startTime: values.bookingTime?.from!,
    endTime: values.bookingTime?.to!,
    categoryId: values.selectedVehicleCategory!,
  });

  const { data: branchData } = useRequestSingleBranchQuery(branchId);
  const dateLocale = useDateLocale();

  const bookingStartDate =
    values.bookingDate?.from &&
    format(
      mergeDateTime(values.bookingDate.from, values.bookingTime!.from),
      DateFormats.FULL_DATE_TIME,
      { locale: dateLocale },
    );

  const bookingEndDate =
    (values.bookingDate?.to || values.bookingDate?.from) &&
    format(
      mergeDateTime(values.bookingDate.to ?? values.bookingDate.from!, values.bookingTime!.to),
      DateFormats.FULL_DATE_TIME,
      { locale: dateLocale },
    );

  const isBusinessTrip = values.selectedPaymentType === 'BUSINESS';

  if (isFetchingAdditions) {
    return <Loader cover />;
  }

  return (
    <div className={styles.summary}>
      <div className={styles.summary__image}>
        <img src={vehicleCategory?.appProperties?.image} alt={vehicleCategory?.name} />
      </div>

      <Heading marginBottom={24} align="center" size={4} weight="regular">
        {vehicleCategory?.name}
      </Heading>

      <Separator gapTop={16} gapBottom={24} />

      <Paragraph marginBottom={4} size={4} textColor="text-400">
        {isBusinessTrip ? (
          <Trans id="bookings.createSummary.businessTrip">Business trip</Trans>
        ) : (
          <Trans id="bookings.createSummary.personalTrip">Personal trip</Trans>
        )}
      </Paragraph>
      <Paragraph marginBottom={0} weight="medium">
        {isBusinessTrip ? values.reason : getPersonalPaymentName(personalPayments?.[0])}
      </Paragraph>

      <Separator gapTop={16} gapBottom={24} />

      <Row alignItems="center" justify="space-between">
        <Column>
          <Paragraph marginBottom={4} size={4} textColor="text-400">
            <Trans id="bookings.createSummary.location">Location</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} weight="medium">
            {branchData?.name}
          </Paragraph>
        </Column>
        {branchData?.contactInfo?.locationGuidelines && (
          <Column>
            <Button onClick={() => setGuidelinesModal(true)} variation="secondary" size="small">
              <Trans id="bookings.createSummary.locationGuidelines">Location guidelines</Trans>
            </Button>
          </Column>
        )}
      </Row>
      <Separator gapTop={24} gapBottom={24} />

      <Row gapSm="xs" marginBottom={0}>
        <Column sm={12} md={5}>
          <Paragraph marginBottom={4} size={4} textColor="text-400">
            <Trans id="bookings.createSummary.pickupDate">Pickup date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} weight="medium">
            {bookingStartDate}
          </Paragraph>
        </Column>
        <Column sm={12} md={5}>
          <Paragraph marginBottom={4} size={4} textColor="text-400">
            <Trans id="bookings.createSummary.returnDate">Return date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} weight="medium">
            {bookingEndDate}
          </Paragraph>
        </Column>
      </Row>
      <Separator gapTop={24} gapBottom={24} />

      <PricingBreakDown branchId={branchId} vehicleCategory={vehicleCategory!} />
      {branchData?.contactInfo?.locationGuidelines && (
        <LocationGuidelinesModal
          onClose={() => setGuidelinesModal(false)}
          opened={guidelinesModal}
          locationGuidelines={branchData.contactInfo.locationGuidelines}
        />
      )}
    </div>
  );
};

export default memo(BookingSummary);
