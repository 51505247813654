import { stringify, parse } from 'qs';
import { DataViewsAddNewFilter, DataViewsAddNewSort } from '@wunder/ui-components';
import { omit } from 'lodash';

type DataViewSort = DataViewsAddNewSort[] | null | undefined;
type DataViewFilters = DataViewsAddNewFilter[] | null | undefined;
type DataViewsFilterArgs = DataViewSort | DataViewFilters;
interface QueryStringAdditions {
  skip?: string[];
  init?: QueryStringArgs;
}

interface QueryStringArgs {
  [s: string]: number | number[] | string | string[] | boolean | null;
}

/**
 * DataViews already provide queryStrings for sorting and filters, but we need to parse them to key/values pairs
 * so its easily readable by parseQueryString func
 * @param filterArgs
 */
export const parseDataViewQueryString = (filterArgs: DataViewsFilterArgs[]): QueryStringArgs => {
  const queryStrings: QueryStringArgs = {};

  if (!filterArgs || (filterArgs && !filterArgs.filter(Boolean).length)) return queryStrings;

  filterArgs.forEach((filterGroup) => {
    (filterGroup ?? []).forEach((filter) => {
      Object.assign(queryStrings, parse(filter.queryString!));
    });
  });

  return queryStrings;
};

/**
 * Make query string from object
 * Accepts properties in object as array or single value
 * @param args - object used to create query string.
 * @param skip - properties to avoid parsing as query args
 * @param init - inital query args in format that is used for `URLSearchParams`
 * @returns query string `(eg. foo=22&foo=11&baz=test.. )`
 */
export const parseQueryString = (
  args: QueryStringArgs,
  { skip = [], init = {} }: QueryStringAdditions | undefined = {},
) => {
  Object.entries(args).forEach(([key, value]) => {
    if (value === null || value === undefined || skip.includes(key)) return;

    // case when value is array
    if (Array.isArray(value)) {
      init[key] = [];
      value.forEach((val) => {
        (init[key] as string[]).push(String(val));
      });
      return;
    }
    // remove empties
    if (`${value}`.length) init[key] = value;
  });

  return stringify(init, { arrayFormat: 'repeat' });
};

interface PrepareDataViewsQueryStringsT {
  args: Record<string, any>;
  additionalFieldsToOmit?: string[];
  skipFields?: string[];
}

export const prepareDataViewsQueryStrings = ({
  args,
  additionalFieldsToOmit = [],
  skipFields = [],
}: PrepareDataViewsQueryStringsT) => {
  return parseQueryString(omit(args, ['filters', 'sort', ...additionalFieldsToOmit]), {
    skip: skipFields,
    init: parseDataViewQueryString([args.filters, args.sort]),
  });
};
