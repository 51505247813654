import { StepName } from '@/core/features/account/types';
import { RootState } from '@/core/redux';
import { AccountService } from '@/features/account/services/Account.service';
import { AvailableBookingStationFilters, AvailableBookingStations } from '@/features/booking/types';
import { UserData, UserEntityConfig } from '@/features/auth/types/User.types';
import { FilterPreset } from '@wunder/ui-components';
import { UserEntityKeys } from '../enums';
import { createSelector } from '@reduxjs/toolkit';
import { LngLat } from '@/features/geomap/types';
import { PaymentProvider } from '@/core/types';

export const getIsStepCompleted = (state: RootState, stepName: StepName): boolean =>
  state.account.steps[stepName].isComplete;

export const getAllStepsCompleted = (state: RootState): boolean =>
  Object.values(state.account.steps).every(({ isComplete }) => isComplete);

export const getSelectedCardId = (state: RootState): string | null =>
  state.account.steps.payment.selectedCardId;

export const getStepsStatus = (state: RootState) => state.account.steps;

const meDataSelector = AccountService.endpoints.requestMeData.select();

export const getMeData = (state: RootState): UserData | undefined => meDataSelector(state)?.data;

export const getUserConfigForEntity = createSelector(
  getMeData,
  (state: RootState, entity: UserEntityKeys) => entity,
  (meData, entity): undefined | UserEntityConfig => meData?.customProperties?.userConfig?.[entity],
);

export const getUserFilterPresetForEntity = createSelector(
  getMeData,
  (state: RootState, entity: UserEntityKeys) => entity,
  (meData, entity): undefined | FilterPreset[] => {
    const entityPreset = meData?.customProperties?.presets?.[entity];
    if (!entityPreset) return undefined;

    return Object.values(entityPreset);
  },
);

export const getPromoCode = (state: RootState): string | null =>
  state.account.steps.payment.promoCode;

export const getPaymentProvider = (state: RootState): PaymentProvider | null =>
  state.account.steps.payment.provider;

export const getActiveFilterValues = (
  state: RootState,
): Partial<AvailableBookingStationFilters> | null => state.account.activeFilterValues;

export const getFilteredStationsData = (state: RootState): AvailableBookingStations[] | null =>
  state.account.filteredStationsData;

export const getIsFetchingFilteredStationsData = (state: RootState): boolean =>
  state.account.isFetchingFilteredStationsData;

export const getBookingCreationProcessStatus = (state: RootState): boolean | null =>
  state.account.bookingCreationProcessStarted;

export const getClearFiltersRequest = (state: RootState): boolean =>
  state.account.clearFiltersRequested;

export const getUserLocationCoordinates = (state: RootState): LngLat | null =>
  state.account.userLocationCoordinates;
