import { FC } from 'react';
import { Column, Heading, Row, Tags } from '@wunder/ui-components';
import { Booking } from '@/features/booking/types';
import { VehicleCategory } from '@/features/vehicles/types';
import { useRequestBookingByIdQuery } from '@/features/booking/services/Booking.service';
import styles from '@/core/features/myTrips/assets/scss/components/Trips/TripHeader.module.scss';

export interface MyTripT {
  tripId: Booking['id'];
  tripState: Booking['state'];
  categoryName: VehicleCategory['name'];
}

const TripHeader: FC<MyTripT> = ({ categoryName, tripId, tripState }) => {
  const { data: trip } = useRequestBookingByIdQuery(
    { id: tripId },
    {
      skip: tripState !== 'DELIVERED' && tripState !== 'SUCCESSFUL',
    },
  );
  const { name: vehicleName, code: vehicleCode, licensePlate } = trip?.vehicle ?? {};
  const displayName = vehicleName ?? categoryName;

  return (
    <Row alignItems="center" gapSm="sm" marginBottom={0}>
      <Column>
        <Heading className={styles['trip-header__title']} size={3} marginBottom={0}>
          {displayName}
        </Heading>
      </Column>
      {(licensePlate || vehicleCode) && (
        <Column>
          <Tags size="tiny" weight="bold" content={licensePlate ?? vehicleCode} />
        </Column>
      )}
    </Row>
  );
};

export default TripHeader;
