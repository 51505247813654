import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormikProvider, useFormik } from 'formik';
import {
  AvailableBookingStationArgs,
  AvailableBookingStationFilters,
  BookingFiltersForm,
  BookingSettings,
  ParsedAttributes,
} from '@/features/booking/types';
import { useSearchParams } from 'react-router';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import {
  resetActiveFilters,
  setActiveFilterValues,
  setClearFiltersRequested,
} from '@/features/account/redux/account.reducer';
import { useMap, MapMouseEvent } from '@wunder/ui-components';
import FormFields from '@/features/booking/components/Filters/FormFields';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { useRequestVehicleAttributesQuery } from '@/features/vehicles/services/Vehicles.service';
import { keys, omit } from 'lodash';
import { getClearFiltersRequest } from '@/features/account/redux/account.selectors';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { SettingsManagement } from '@/core/types';

interface FiltersFormT {
  onSubmit: (args: Partial<AvailableBookingStationArgs>) => Promise<unknown> | null;
  initialValues: BookingFiltersForm;
  bookingSettings?: SettingsManagement<BookingSettings>;
}

// This suffix is needed because form elements have name with id ( number )
// Formik automatically parses them as array index but we want them to be objects
export const GROUP_ITEM_SUFFIX = 'item-';

const FiltersForm: React.FC<FiltersFormT> = ({ onSubmit, initialValues, bookingSettings }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useTypedDispatch();
  const headerPortalRef = useRef<HTMLElement>(document.getElementById('booking-filters'));
  const { current: mapInstance } = useMap();
  const { data: vehicleAttributesAPI } = useRequestVehicleAttributesQuery();
  const clearFiltersRequested = useTypedSelector(getClearFiltersRequest);

  const updateActiveFilterValues = (args: Partial<AvailableBookingStationFilters>) => {
    const {
      vehicleAttributes,
      startTime,
      endTime,
      location,
      flexibleDurationType,
      dateFilterType,
      boundingBoxCoordinates,
    } = args;
    const hasAttributesFilter = vehicleAttributes && !!keys(vehicleAttributes).length;

    if (
      !startTime &&
      !endTime &&
      !hasAttributesFilter &&
      !boundingBoxCoordinates &&
      !flexibleDurationType
    ) {
      dispatch(resetActiveFilters());
    } else {
      dispatch(
        setActiveFilterValues({
          startTime,
          endTime,
          location,
          dateFilterType,
          flexibleDurationType,
          boundingBoxCoordinates,
          vehicleAttributes,
        }),
      );
    }
  };

  /**
   * Attribute filters need to be parsed for backend to understand them
   */
  const parseAttributeValues = (
    attributes: BookingFiltersForm['vehicleAttributes'],
  ): ParsedAttributes => {
    const parsedValues: ParsedAttributes = {};

    if (!attributes) return parsedValues;

    Object.entries(attributes)
      .filter(([_, value]) => value)
      .forEach(([key, attributeValue]) => {
        const vehicleAttributeConfig = vehicleAttributesAPI!.find((attribute) =>
          attribute.attributes.some(({ id }) => String(id) === key.replace(GROUP_ITEM_SUFFIX, '')),
        );

        if (!vehicleAttributeConfig) {
          return;
        }

        if (!parsedValues[`${vehicleAttributeConfig.filterType}`]) {
          parsedValues[`${vehicleAttributeConfig.filterType}`] = [];
        }

        if (attributeValue) {
          parsedValues[`${vehicleAttributeConfig.filterType}`].push(
            key.replace(GROUP_ITEM_SUFFIX, ''),
          );
        }
      });

    return parsedValues;
  };

  const parseStartEndDate = ({
    bookingDate,
    bookingTime,
  }: Pick<BookingFiltersForm, 'bookingTime' | 'bookingDate'>): Pick<
    AvailableBookingStationArgs,
    'startTime' | 'endTime'
  > => {
    const startTime =
      bookingDate?.from &&
      new Date(mergeDateTime(bookingDate.from, bookingTime!.from)).toISOString();

    const endTime =
      (bookingDate?.from || bookingDate?.to) &&
      new Date(mergeDateTime(bookingDate.to ?? bookingDate.from!, bookingTime!.to)).toISOString();

    return { startTime, endTime };
  };

  const onLocationChange = async (values: BookingFiltersForm = { dateFilterType: 'SPECIFIC' }) => {
    const {
      boundingBoxCoordinates,
      location,
      dateFilterType,
      vehicleAttributes,
      bookingDate,
      bookingTime,
    } = values;

    const parsedAttributes = parseAttributeValues(vehicleAttributes);
    const { startTime, endTime } = parseStartEndDate({ bookingDate, bookingTime });

    setSearchParams((params) => {
      if (boundingBoxCoordinates) {
        params.set('boundingBoxCoordinates', boundingBoxCoordinates.join(','));
      } else {
        params.delete('boundingBoxCoordinates');
      }

      if (location) {
        params.set('location', location);
      } else {
        params.delete('location');
      }

      return params;
    });

    updateActiveFilterValues({
      startTime,
      endTime,
      location,
      dateFilterType,
      boundingBoxCoordinates,
      vehicleAttributes: parsedAttributes,
    });

    if (boundingBoxCoordinates) {
      mapInstance?.fitBounds(
        [
          [boundingBoxCoordinates[0], boundingBoxCoordinates[1]],
          [boundingBoxCoordinates[2], boundingBoxCoordinates[3]],
        ],
        { duration: 500 },
      );
    }
  };

  const onFilterSubmit = async (values: BookingFiltersForm) => {
    const {
      dateFilterType,
      bookingDate,
      vehicleAttributes,
      bookingTime,
      location,
      boundingBoxCoordinates,
      flexibleDurationType,
    } = values;

    await onLocationChange(values);

    const parsedAttributes = parseAttributeValues(vehicleAttributes);
    const { startTime, endTime } = parseStartEndDate({ bookingDate, bookingTime });

    const hasActiveFilters = startTime ?? vehicleAttributes ?? flexibleDurationType;

    setSearchParams((params) => {
      if (hasActiveFilters) {
        params.set(
          'bookingFilters',
          encodeURIComponent(
            JSON.stringify({
              ...omit(values, ['vehicleFilters']),
              bookingDate: values.bookingDate
                ? {
                    from: values.bookingDate.from,
                    to: values.bookingDate.to ?? values.bookingDate.from,
                  }
                : undefined,
            }),
          ),
        );
      } else {
        params.delete('bookingFilters');
      }

      return params;
    });

    await onSubmit({
      startTime,
      endTime,
      dateFilterType,
      flexibleDurationType,
      vehicleAttributes: parsedAttributes,
      location,
    });

    updateActiveFilterValues({
      startTime,
      endTime,
      location,
      dateFilterType,
      flexibleDurationType,
      boundingBoxCoordinates,
      vehicleAttributes: parsedAttributes,
    });
  };

  const mapLocationChanges = useCallback(
    (mapEvent: MapMouseEvent) => {
      const newSearchParams = searchParams;
      const zoom = mapEvent.target.getZoom();
      newSearchParams.set('z', zoom.toString());

      const center = mapEvent.target.getCenter();
      newSearchParams.set('c', [center.lng, center.lat].toString());

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const formInstance = useFormik<BookingFiltersForm>({
    initialValues,
    onSubmit: onFilterSubmit,
  });

  useEffect(() => {
    const {
      dateFilterType = 'SPECIFIC',
      bookingDate,
      bookingTime,
      vehicleAttributes,
      flexibleDurationType,
      location,
      boundingBoxCoordinates,
    } = initialValues ?? {};

    const parsedDates =
      bookingDate && bookingTime && parseStartEndDate({ bookingDate, bookingTime });
    const parsedAttributes = parseAttributeValues(vehicleAttributes);

    updateActiveFilterValues({
      startTime: parsedDates?.startTime,
      endTime: parsedDates?.endTime,
      location,
      flexibleDurationType,
      boundingBoxCoordinates,
      vehicleAttributes: parsedAttributes,
      dateFilterType,
    });

    onSubmit({
      dateFilterType,
      flexibleDurationType,
      startTime: parsedDates?.startTime,
      endTime: parsedDates?.endTime,
      vehicleAttributes: parsedAttributes,
      location,
    });

    if (boundingBoxCoordinates && location) {
      mapInstance?.fitBounds(
        [
          [boundingBoxCoordinates[0], boundingBoxCoordinates[1]],
          [boundingBoxCoordinates[2], boundingBoxCoordinates[3]],
        ],
        { duration: 1 },
      );
    }

    // Should only trigger initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filters can be cleared from alot of places on the app, so we need to have this listener to trigger filter clear
    if (clearFiltersRequested) {
      const { lng, lat } = mapInstance!.getCenter();

      setSearchParams({
        z: mapInstance!.getZoom().toString(),
        c: `${lng},${lat}`,
      });

      formInstance.setValues({
        location: '',
        date: undefined,
        dateFilterType: 'SPECIFIC',
        bookingDate: undefined,
        bookingTime: {
          from: formatInitialTime({
            initialTime: bookingSettings?.value?.properties?.preselectedStartingTime,
            buffer: 60,
          }),
          to: formatInitialTime({
            initialTime: bookingSettings?.value?.properties?.preselectedEndingTime,
            buffer: 120,
          }),
        },
        boundingBoxCoordinates: undefined,
        vehicleAttributes: undefined,
        vehicleFilters: undefined,
        flexibleDurationType: undefined,
      });
      dispatch(resetActiveFilters());
      dispatch(setClearFiltersRequested(false));
    }

    // We dont need all these dependencies used here, we only want this to be triggered on clearFiltersRequested change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setSearchParams, clearFiltersRequested]);

  useEffect(() => {
    // @ts-ignore
    mapInstance?.on('moveend', mapLocationChanges);

    return () => {
      // @ts-ignore
      mapInstance?.off('moveend', mapLocationChanges);
    };
  }, [mapInstance, mapLocationChanges]);

  return createPortal(
    <FormikProvider value={formInstance}>
      <FormFields />
    </FormikProvider>,
    headerPortalRef.current!,
  );
};

export default FiltersForm;
