import { FC, useState, useEffect } from 'react';
import zoomControlsModules from '../assets/scss/ZoomControls.module.scss';
import { Paragraph, useMap } from '@wunder/ui-components';
import cn from 'classnames';

interface ZoomControlsT {
  className?: string;
}

/**
 * Needs to be inside WunderMaps component
 */
const ZoomControls: FC<ZoomControlsT> = ({ className }) => {
  /**
   * Map instance
   */
  const { current: mapInstance } = useMap();

  /**
   * Convert zoom values to percentages based on min and max zoom
   */
  const calculateZoomPercentage = (currentZoom: number) => {
    const minZoom = mapInstance!.getMinZoom();
    const maxZoom = mapInstance!.getMaxZoom();

    return Math.ceil(((currentZoom - minZoom) / (maxZoom - minZoom)) * 100);
  };

  /**
   * Zoom percentage displayed
   */
  const [zoomPercentage, setZoomPercentage] = useState<number>(
    calculateZoomPercentage(mapInstance!.getZoom()),
  );

  useEffect(() => {
    const handleZoom = () => setZoomPercentage(calculateZoomPercentage(mapInstance!.getZoom()));

    mapInstance!.on('zoomend', handleZoom);

    return () => {
      mapInstance!.off('zoomend', handleZoom);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn(zoomControlsModules['zoom-control'], className)}>
      <button
        aria-label="Karte herauszoomen"
        type="button"
        onClick={() => {
          mapInstance!.zoomOut();
        }}
      >
        -
      </button>
      <Paragraph
        className={zoomControlsModules['zoom-control__indication']}
        marginBottom={0}
        size={4}
        weight="medium"
      >
        {zoomPercentage} %
      </Paragraph>
      <button
        aria-label="Karte einzoome"
        type="button"
        onClick={() => {
          mapInstance!.zoomIn();
        }}
      >
        +
      </button>
    </div>
  );
};

export default ZoomControls;
