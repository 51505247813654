import AuthenticationLayout from '@/features/auth/components/AuthenticationLayout';
import { Trans } from '@lingui/react/macro';
import PhoneLoginForm, { PhoneLoginFormValues } from '@/features/auth/components/PhoneLoginForm';
import { useState } from 'react';
import PhoneLoginVerificationForm from '@/features/auth/components/PhoneLoginVerificationForm';
import { Paragraph } from '@wunder/ui-components';
import { Link } from 'react-router';
import {
  useRequestCreateOrSignInWithPhoneNumberMutation,
  useRequestPhoneLoginMutation,
} from '@/features/auth/services/Auth.service';
import { generateSignatureHash } from '@/features/auth/utils/generateSignatureHash';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import useAfterAuthPathResolver from '@/features/auth/hooks/useAfterAuthPathResolver';
import useMailtoLink from '@/core/hooks/useMailtoLink';
import { ApiError } from '@/core/types';
import { useRequestUserUpdateMutation } from '@/features/account/services/Account.service';

const PhoneAuthentication = () => {
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [updateUser] = useRequestUserUpdateMutation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const resolvePath = useAfterAuthPathResolver();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const [createOrSignup] = useRequestCreateOrSignInWithPhoneNumberMutation();
  const [login, { error: loginError }] = useRequestPhoneLoginMutation();
  const error = loginError as ApiError;
  const { mailto } = useMailtoLink();
  const [newsletterSelection, setNewsletterSelection] = useState<Record<string, boolean>>({});

  const handleCreateCode = async ({ phone, newsletterOptions }: PhoneLoginFormValues) => {
    if (resolvedBranch) {
      const { timestamp, signature } = await generateSignatureHash(phone);
      createOrSignup({
        phoneNumber: `+${phone}`,
        branchId: String(resolvedBranch.id),
        timestamp,
        signature,
      })
        .unwrap()
        .then(() => {
          setNewsletterSelection(newsletterOptions);
          setWaitingForCode(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setPhoneNumber(phone);
  };

  const handleLogin = (code: string) => {
    login({ phoneNumber: `+${phoneNumber}`, code })
      .unwrap()
      .then(() => {
        updateUser({ newsletterOptions: newsletterSelection });
        resolvePath();
      })
      .catch(void 0);
  };

  return (
    <AuthenticationLayout
      title={
        waitingForCode ? (
          <Trans id="auth.phoneLoginCode.pageTitle">Confirm your number</Trans>
        ) : (
          <Trans id="auth.phoneLoginForm.pageTitle">Welcome</Trans>
        )
      }
      alignCenter
      form={
        waitingForCode ? (
          <PhoneLoginVerificationForm
            onResendCode={handleCreateCode}
            phone={phoneNumber}
            login={handleLogin}
            error={error}
            countdown={120}
          />
        ) : (
          <PhoneLoginForm onSubmit={handleCreateCode} branchId={resolvedBranch?.id} />
        )
      }
      footer={
        waitingForCode && (
          <Paragraph textColor="text-400" size={5}>
            <Trans id="auth.phoneLoginCode.footer">Still having trouble?</Trans>{' '}
            <Link to={mailto}>
              <Trans id="auth.phoneLoginCode.contactSupport">Contact support</Trans>
            </Link>
          </Paragraph>
        )
      }
    />
  );
};

export default PhoneAuthentication;
