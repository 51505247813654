import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import '@wunder/ui-components/dist/lib/index.css';
import '@/core/assets/scss/index.scss';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import App from '@/core/App';
import { getTenant } from '@/core/api/restClient';

// To reduce the load on sentry as well as session replays there are some errors that we want to ignore
const ignoredSerializedErrors = [
  ['message', 'Aborted'],
  ['name', 'AbortError'],
  ['message', 'Rejected'],
  ['httpStatus', 406],
  ['httpStatus', 403],
  ['httpStatus', 409],
  ['httpStatus', 412],
  ['errorCode', 'U304.3'],
  ['errorCode', 'S104'],
  ['errorCode', 'U149.1'],
  ['errorCode', 'S120'],
  ['errorCode', 'DL102'],
];

const ignoredExceptionErrors = [
  'error loading dynamically imported module',
  'Importing a module script failed.',
  'https://api.mapbox.com/',
  'https://checkoutshopper-live.cdn.adyen.com',
];

Sentry.init({
  dsn: 'https://7898af9c088247dfa85f622ad2e26a7f@o369452.ingest.sentry.io/4505442531803136',
  environment: import.meta.env.VITE_TARGET_ENV,
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: true,
      beforeErrorSampling: (event) => {
        const exceptionErrors = event?.exception?.values?.[0]?.value;

        if (exceptionErrors) {
          // eslint-disable-next-line no-restricted-syntax
          for (const value of ignoredExceptionErrors) {
            if (exceptionErrors.includes(value)) {
              return false;
            }
          }
        }

        const serializedData = event?.extra?.__serialized__ as Record<string, any>;

        if (serializedData) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of ignoredSerializedErrors) {
            if (
              serializedData[key] &&
              typeof serializedData[key] === 'string' &&
              serializedData[key].includes(value)
            ) {
              return false;
            }
          }
        }

        return true;
      },
    }),
  ],
  beforeSend: (event) => {
    if (!import.meta.env.VITE_TARGET_ENV) {
      return null;
    }

    const exceptionErrors = event?.exception?.values?.[0]?.value;

    if (exceptionErrors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const value of ignoredExceptionErrors) {
        if (exceptionErrors.includes(value)) {
          return null;
        }
      }
    }

    const serializedData = event?.extra?.__serialized__ as Record<string, any>;

    if (serializedData) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of ignoredSerializedErrors) {
        if (
          serializedData[key] &&
          typeof serializedData[key] === 'string' &&
          serializedData[key].includes(value)
        ) {
          return null;
        }
      }
    }

    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_TARGET_ENV === 'production' ? 0.3 : 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: import.meta.env.VITE_TARGET_ENV === 'production' ? 1.0 : 0,
});

Sentry.setTag('app', 'BookingWeb');
Sentry.setTag('tenant', getTenant());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
