import { useProfileSchema } from '@/features/auth/hooks/useProfileSchema';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import EditFormSection from '@/core/components/EditFormSection';
import {
  Button,
  Column,
  DatePickerInput,
  Loader,
  PhoneNumber,
  Row,
  Text,
} from '@wunder/ui-components';
import { subYears } from 'date-fns';
import CitySelect from '@/features/geomap/components/Form/CitySelect';
import CountrySelect from '@/features/geomap/components/Form/CountrySelect';
import { useLingui } from '@lingui/react';
import { useFormikContext } from 'formik';
import { useTypedSelector } from '@/core/redux/hooks';
import { getMeData } from '@/features/account/redux/account.selectors';
import { useMemo } from 'react';

const BasicInfo = () => {
  const meData = useTypedSelector(getMeData);
  const {
    profile: { firstName, lastName, email, postcode, phone, birthdate, city, street, country },
  } = useProfileSchema();
  const { isValid, dirty, isSubmitting, submitForm } = useFormikContext();
  useLingui();

  const {
    editableCountry,
    editableCity,
    editableBirthdate,
    editableEmail,
    editablePhone,
    editablePostcode,
    editableStreet,
    editableFirstName,
    editableLastName,
  } = useMemo(() => {
    return {
      editableFirstName: !!meData?.firstName && !firstName.editable,
      editableLastName: !!meData?.lastName && !lastName.editable,
      editableEmail: !!meData?.email && !email.editable,
      editablePhone: !!meData?.phone && !phone.editable,
      editableBirthdate: !!meData?.birthdate && !birthdate.editable,
      editableStreet: !!meData?.street && !street.editable,
      editableCity: !!meData?.city && !city.editable,
      editablePostcode: !!meData?.postcode && !postcode.editable,
      editableCountry: !!meData?.country && !country.editable,
    };
  }, [meData, firstName, lastName, email, postcode, phone, birthdate, city, street, country]);

  return (
    <EditFormSection
      title={<Trans id="accountEdit.basicInfo.title">Basic info</Trans>}
      description={
        <Trans id="accountEdit.basicInfo.description">
          Update your basic info effortlessly to ensure a smooth and personalized experience across
          the platform.
        </Trans>
      }
      content={
        <>
          {isSubmitting && <Loader cover />}
          {(firstName.visible || lastName.visible) && (
            <Row marginBottom={8} gapSm="sm">
              {firstName.visible && (
                <Column sm={12} md={lastName.visible ? 6 : 12}>
                  <Text
                    name="firstName"
                    fieldAttr={{
                      id: 'firstName',
                      placeholder: t({
                        id: 'account.edit.firstNamePlaceholder',
                        message: 'First name',
                      }),
                      required: firstName.required,
                      disabled: editableFirstName,
                      readOnly: editableFirstName,
                    }}
                    fieldProps={{
                      label: t({ id: 'account.edit.firstNameLabel', message: 'First name' }),
                    }}
                  />
                </Column>
              )}
              {lastName.visible && (
                <Column sm={12} md={firstName.visible ? 6 : 12}>
                  <Text
                    name="lastName"
                    fieldAttr={{
                      id: 'lastName',
                      placeholder: t({
                        id: 'account.edit.lastNamePlaceholder',
                        message: 'Last name',
                      }),
                      required: lastName.required,
                      disabled: editableLastName,
                      readOnly: editableLastName,
                    }}
                    fieldProps={{
                      label: t({ id: 'account.edit.lastNameLabel', message: 'Last name' }),
                    }}
                  />
                </Column>
              )}
            </Row>
          )}
          {email.visible && (
            <Row marginBottom={8} gapSm="sm">
              <Column sm={12}>
                <Text
                  name="email"
                  fieldAttr={{
                    id: 'email',
                    placeholder: t({ id: 'account.edit.emailPlaceholder', message: 'E-mail' }),
                    required: email.required,
                    readOnly: editableEmail,
                    disabled: editableEmail,
                  }}
                  fieldProps={{ label: t({ id: 'account.edit.emailLabel', message: 'E-mail' }) }}
                />
              </Column>
            </Row>
          )}
          {phone.visible && (
            <Row marginBottom={8} gapSm="sm">
              <Column sm={12}>
                <PhoneNumber
                  name="phone"
                  fieldAttr={{
                    id: 'phone',
                    placeholder: t({
                      id: 'account.edit.phonePlaceholder',
                      message: 'Phone number',
                    }),
                    required: phone.required,
                    readOnly: editablePhone,
                    disabled: editablePhone,
                  }}
                  fieldProps={{
                    label: t({ id: 'account.edit.phoneLabel', message: 'Phone number' }),
                  }}
                />
              </Column>
            </Row>
          )}
          {birthdate.visible && (
            <Row marginBottom={8} gapSm="sm">
              <Column sm={12}>
                <DatePickerInput
                  fieldAttr={{
                    id: 'birthdate',
                    placeholder: t({
                      id: 'account.edit.birthdatePlaceholder',
                      message: 'Date of birth',
                    }),
                    required: birthdate.required,
                    readOnly: editableBirthdate,
                    disabled: editableBirthdate,
                  }}
                  name="birthdate"
                  fieldProps={{
                    label: t({
                      id: 'account.edit.birthdateLabel',
                      message: 'Date of birth',
                    }),
                    allowableSelectionRange: {
                      minDate: subYears(new Date(), 100).toString(),
                      maxDate: new Date().toString(),
                    },
                  }}
                />
              </Column>
            </Row>
          )}
          <Row marginBottom={8} gapSm="sm">
            <Column sm={12}>
              <Text
                name="customProperties.birthPlace"
                fieldAttr={{
                  id: 'customProperties.birthPlace',
                  placeholder: t({
                    id: 'account.edit.birthplacePlaceholder',
                    message: 'Birth place',
                  }),
                }}
                fieldProps={{
                  label: t({ id: 'account.edit.birthplaceLabel', message: 'Birth place' }),
                }}
              />
            </Column>
          </Row>
          {street.visible && (
            <Row marginBottom={8} gapSm="sm">
              <Column sm={12}>
                <Text
                  name="street"
                  fieldAttr={{
                    id: 'street',
                    placeholder: t({ id: 'account.edit.streetPlaceholder', message: 'Address' }),
                    required: street.required,
                    readOnly: editableStreet,
                    disabled: editableStreet,
                  }}
                  fieldProps={{ label: t({ id: 'account.edit.streetLabel', message: 'Address' }) }}
                />
              </Column>
            </Row>
          )}
          {(city.visible || postcode.visible) && (
            <Row marginBottom={8} gapSm="sm">
              {city.visible && (
                <Column sm={12} md={postcode.visible ? 6 : 12}>
                  <CitySelect
                    fieldAttr={{
                      required: city.required,
                      readonly: editableCity,
                      disabled: editableCity,
                      placeholder: t({ id: 'geomap.citySelect.placeholder', message: 'City' }),
                    }}
                  />
                </Column>
              )}
              {postcode.visible && (
                <Column sm={12} md={city.visible ? 6 : 12}>
                  <Text
                    name="postcode"
                    fieldAttr={{
                      id: 'postcode',
                      placeholder: t({ id: 'account.edit.postcodePlaceholder', message: 'ZIP' }),
                      required: postcode.required,
                      readOnly: editablePostcode,
                      disabled: editablePostcode,
                    }}
                    fieldProps={{ label: t({ id: 'account.edit.postcodeLabel', message: 'ZIP' }) }}
                  />
                </Column>
              )}
            </Row>
          )}
          {country.visible && (
            <Row marginBottom={8} gapSm="sm">
              <Column sm={12}>
                <CountrySelect
                  fieldAttr={{
                    id: 'country',
                    placeholder: t({ id: 'account.edit.countryPlaceholder', message: 'Country' }),
                    required: country.required,
                    disabled: editableCountry,
                  }}
                  readonly={editableCountry}
                />
              </Column>
            </Row>
          )}

          <Row justify="flex-end">
            <Button onClick={submitForm} disabled={!(isValid && dirty)}>
              <Trans id="general.save">Save</Trans>
            </Button>
          </Row>
        </>
      }
    />
  );
};

export default BasicInfo;
