import React, { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@wunder/ui-components';
import {
  useRequestUserUpdateMutation,
  useRequestMeDataQuery,
} from '@/features/account/services/Account.service';
import { Trans } from '@lingui/react/macro';

interface OnboardingActionButtonsT {
  nextButton: ReactElement;
}

const OnboardingActionButtons: FC<OnboardingActionButtonsT> = ({ nextButton }) => {
  const navigate = useNavigate();
  const { data: userData, refetch } = useRequestMeDataQuery();
  const [updateUser, { isLoading: isLoadingSkipAccountSetup }] = useRequestUserUpdateMutation();

  const skipAccountSetup = () => {
    if (userData?.customProperties?.accountSetupSkipped) {
      // Already skipped, no need to update customProperties, just navigate to the path
      navigate('/');
      return;
    }

    updateUser({
      customProperties: {
        ...(userData?.customProperties ?? {}),
        accountSetupSkipped: true,
      },
      skipInvalidation: true,
    })
      .unwrap()
      .then(() => refetch().unwrap())
      .then(() => {
        navigate('/');
      });
  };

  return (
    <>
      <Button variation="secondary" loading={isLoadingSkipAccountSetup} onClick={skipAccountSetup}>
        <Trans id="general.skip">Skip</Trans>
      </Button>
      {nextButton}
    </>
  );
};

export default OnboardingActionButtons;
