import React, { FC } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { AppWhiteLabel } from '@/features/booking/types';
import { SettingsKeys } from '@/core/enums';
import { Svg } from '@wunder/ui-components';

interface WhiteLabelLogoT {
  type?: 'small' | 'big';
}

const WhiteLabelLogo: FC<WhiteLabelLogoT> = ({ type = 'big' }) => {
  const whiteLabelLogo = useTypedSelector((state) =>
    getSetting<AppWhiteLabel>(state, SettingsKeys.WHITELABEL_APP),
  );

  if (type === 'small') {
    return whiteLabelLogo?.logo?.small ? (
      <img src={whiteLabelLogo?.logo?.small} alt="Logo" />
    ) : (
      <Svg icon="wunderLogoLong" height={30} width={150} />
    );
  }

  return whiteLabelLogo?.logo?.big ? (
    <img src={whiteLabelLogo?.logo?.big} alt="Logo" />
  ) : (
    <Svg icon="wunderLogoLong" width={180} height={30} />
  );
};

export default WhiteLabelLogo;
