import { FC } from 'react';
import { Paragraph } from '@wunder/ui-components';

interface VehicleCategoryDescriptionT {
  description?: string;
}

const VehicleCategoryDescription: FC<VehicleCategoryDescriptionT> = ({ description }) => {
  if (!description) return null;

  return (
    <Paragraph marginBottom={16} size={4} textColor="text-400">
      <span
        role="contentinfo"
        dangerouslySetInnerHTML={{
          __html: description.replaceAll('\n', '<br>')!,
        }}
      />
    </Paragraph>
  );
};

export default VehicleCategoryDescription;
