import React from 'react';
import { Trans } from '@lingui/react/macro';
import { Button } from '@wunder/ui-components';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SettingsKeys } from '@/core/enums';
import { SupportMethods } from '@/core/types';

const ContactSupport = () => {
  const supportMethods = useTypedSelector((state) =>
    getSetting<SupportMethods>(state, SettingsKeys.SUPPORT_METHODS),
  );

  if (!supportMethods?.faqUrl) {
    return null;
  }

  return (
    <Button
      onClick={() => window.open(supportMethods?.faqUrl, '_blank')}
      iconName="headphones"
      variation="link"
    >
      <Trans id="sidebar.customerSupport">Customer support</Trans>
    </Button>
  );
};

export default ContactSupport;
