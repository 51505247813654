import EditFormSection from '@/core/components/EditFormSection';
import { Trans } from '@lingui/react/macro';
import { Paragraph, Row, Switch } from '@wunder/ui-components';
import React, { FC } from 'react';
import { entries, isEmpty } from 'lodash';
import { WhitelabelingAccount } from '@/core/types';
import MarkdownParser from '@/core/components/MarkdownParser';

interface NewsletterT {
  onChange: (key: string, value: boolean) => void;
  newsLetters?: WhitelabelingAccount['newsletters'];
}

const Newsletter: FC<NewsletterT> = ({ onChange, newsLetters }) => {
  if (isEmpty(newsLetters)) {
    return (
      <Paragraph size={2} weight="medium" align="center">
        <Trans id="accountEdit.newsletter.unavailable">No available newsletters.</Trans>
      </Paragraph>
    );
  }

  return (
    <EditFormSection
      title={<Trans id="accountEdit.newsletter.title">Newsletter</Trans>}
      description={
        <Trans id="accountEdit.newsletter.description">
          Get news, announcements, and product updates.
        </Trans>
      }
      content={
        <Row justify="flex-start" column>
          {entries(newsLetters).map(([key, { enabled, text }]) => {
            if (!enabled) {
              return null;
            }

            return (
              <Switch
                key={key}
                fieldProps={{
                  label: (
                    <Paragraph textColor="text-600" marginBottom={0}>
                      <MarkdownParser markdown={text} />
                    </Paragraph>
                  ),
                  labelPlacement: 'left',
                  onChange: (value: boolean) => onChange(`newsletterOptions.${key}`, value),
                }}
                name={`newsletterOptions.${key}`}
                fieldAttr={{ id: `newsletterOptions_${key}` }}
              />
            );
          })}
        </Row>
      }
    />
  );
};

export default Newsletter;
