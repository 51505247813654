import { FC } from 'react';
import { Column, Textarea } from '@wunder/ui-components';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { Trans } from '@lingui/react/macro';
import styles from '../../../../assets/scss/components/BusinessTripReason.module.scss';

interface BusinessTripReasonT {
  isEdit: boolean;
}

const BusinessTripReason: FC<BusinessTripReasonT> = ({ isEdit }) => {
  const { data } = useRequestBookingSettingQuery();
  const businessUserReason = data?.value.reason?.businessUser;

  if (businessUserReason !== 'required' && businessUserReason !== 'optional') {
    return null;
  }

  return (
    <Column sm={12}>
      <Textarea
        name="reason"
        fieldAttr={{
          id: 'businessTripBookingReason',
          required: businessUserReason === 'required',
          className: styles.textarea,
          disabled: isEdit,
        }}
        fieldProps={{
          label: (
            <Trans id="bookings.bookingSummary.businessTripReason">Reason for business trip</Trans>
          ),
        }}
      />
    </Column>
  );
};

export default BusinessTripReason;
