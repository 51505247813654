import React from 'react';
import AccountLayout from '@/features/account/components/Edit/Layout';
import { Button } from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { useNavigate } from 'react-router';
import { booking } from '@/core/pages/Booking/routes';
import EditFormSection from '@/core/components/EditFormSection';
import PaymentProvider from '@/features/payment/components/PaymentProvider';

const AccountEditPaymentMethod = () => {
  const navigate = useNavigate();

  return (
    <AccountLayout
      actions={
        <Button variation="secondary" onClick={() => navigate(booking.path)}>
          <Trans id="general.close">Close</Trans>
        </Button>
      }
    >
      <EditFormSection
        title={<Trans id="accountEdit.paymentMethod.title">Payment method</Trans>}
        description={
          <Trans id="accountEdit.paymentMethod.description">
            Quick and easy way to securely link your preferred payment options to your account.
          </Trans>
        }
        content={<PaymentProvider />}
      />
    </AccountLayout>
  );
};

export default AccountEditPaymentMethod;
