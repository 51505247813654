import React from 'react';
import AccountLayout from '@/features/account/components/Edit/Layout';
import { Button } from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { useNavigate } from 'react-router';
import { booking } from '@/core/pages/Booking/routes';
import BusinessAccount from '@/features/account/components/Edit/BusinessAccount';
import EditFormSection from '@/core/components/EditFormSection';

const AccountEditBusinessAccount = () => {
  const navigate = useNavigate();

  return (
    <AccountLayout
      actions={
        <Button variation="secondary" onClick={() => navigate(booking.path)}>
          <Trans id="general.close">Close</Trans>
        </Button>
      }
    >
      <EditFormSection
        title={<Trans id="accountEdit.business.title">Business</Trans>}
        description={
          <Trans id="accountEdit.business.description">
            Manage your business account or business account membership.
          </Trans>
        }
        content={<BusinessAccount />}
      />
    </AccountLayout>
  );
};

export default AccountEditBusinessAccount;
