import React, { FC, Fragment } from 'react';
import { Row, Column, Tags, Tooltip, Paragraph } from '@wunder/ui-components';
import { VehicleCategoryAttribute } from '@/features/vehicles/types';

interface BookingVehicleCategoryTagsT {
  features: VehicleCategoryAttribute[];
  limit?: number;
}

const VehicleCategoryEquipmentTags: FC<BookingVehicleCategoryTagsT> = ({ features, limit = 2 }) => {
  return (
    <Row marginBottom={0} gapSm="xs">
      {features.slice(0, limit).map((feature) => (
        <Column key={feature.name}>
          <Tags size="tiny" content={feature.name} />
        </Column>
      ))}
      {features.length > limit && (
        <Column>
          <Tooltip
            placement="bottom"
            content={
              <Paragraph marginBottom={0} textColor="text-0" size={3}>
                {features.slice(limit, features.length).map((feature) => (
                  <Fragment key={feature.name}>
                    {feature.name} <br />
                  </Fragment>
                ))}
              </Paragraph>
            }
          >
            <Tags size="tiny" content="..." weight="bold" />
          </Tooltip>
        </Column>
      )}
    </Row>
  );
};

export default VehicleCategoryEquipmentTags;
