import React, { FC, useEffect, useState } from 'react';
import styles from '@/features/booking/assets/scss/components/Filters/DateFilter.module.scss';
import { DropdownWrapper, Tabs, Text } from '@wunder/ui-components';
import { useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';
import { t } from '@lingui/core/macro';
import FlexibleDate from '@/features/booking/components/Filters/FlexibleDate';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { pick } from 'lodash';
import { formatDateLabelString } from '@/features/booking/utils/formatDateLabelString';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import SpecificDate from '@/features/booking/components/Filters/SpecificDate';

interface DatePickerT {
  readOnly?: boolean;
  dropdownVisible?: boolean;
}

const DateFilter: FC<DatePickerT> = ({ readOnly, dropdownVisible }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(dropdownVisible ?? false);
  const { values, setFieldValue } = useFormikContext<BookingFiltersForm>();
  const locale = useDateLocale();
  const { data: bookingSettings } = useRequestBookingSettingQuery();

  useEffect(() => {
    // Used for outside control of dropdown visibility
    if (dropdownVisible !== undefined) setIsDropdownVisible(dropdownVisible);
  }, [dropdownVisible]);

  return (
    <DropdownWrapper
      dropdownContainerClassName={styles['date-filters__dropdown']}
      triggerElement={
        <Text
          name="date"
          fieldAttr={{
            id: 'filterDate',
            readOnly: true,
            onFocus: () => setIsDropdownVisible(true),
            placeholder: t({
              id: 'bookings.filters.pickUpDateReturnDate',
              message: 'Pickup date -> Return date',
            }),
          }}
          fieldProps={{
            clearable: !readOnly,
            onClear: () => {
              void setFieldValue('bookingDate', undefined);
              void setFieldValue('flexibleDurationType', undefined);
            },
            label: '',
            borderless: true,
            iconLeft: { icon: 'calendar', color: 'icon-400' },
          }}
        />
      }
      onRequestClose={() => {
        setIsDropdownVisible(false);

        const labelString = formatDateLabelString(
          values.dateFilterType,
          pick(values, ['bookingDate', 'bookingTime', 'flexibleDurationType']),
          locale,
        );

        void setFieldValue('date', labelString);
      }}
      dropdownContent={
        <>
          <Tabs
            containerClassname={styles['date-filters__tabs']}
            horizontal
            tabs={[
              {
                name: t({ id: 'filters.datepicker.specificDate', message: 'Specific date' }),
                active: values.dateFilterType === 'SPECIFIC',
                tab: 'SPECIFIC',
              },
              {
                name: t({ id: 'filters.datepicker.flexible', message: 'Flexible' }),
                active: values.dateFilterType === 'FLEXIBLE',
                tab: 'FLEXIBLE',
              },
            ]}
            onClick={(clickedTab) => {
              // When switching tabs we have to clear the previous date/time values so we dont trigger both
              if (clickedTab.tab === 'SPECIFIC') {
                void setFieldValue('flexibleDurationType', undefined);
              }

              void setFieldValue('bookingDate', undefined);
              void setFieldValue('bookingTime', {
                from: formatInitialTime({
                  initialTime: bookingSettings?.value?.properties?.preselectedStartingTime,
                  buffer: 60,
                }),
                to: formatInitialTime({
                  initialTime: bookingSettings?.value?.properties?.preselectedEndingTime,
                  buffer: 120,
                }),
              });

              void setFieldValue('dateFilterType', clickedTab.tab);
            }}
          />

          <div className={styles['date-filters__dropdown-content']}>
            {values.dateFilterType === 'SPECIFIC' && <SpecificDate />}
            {values.dateFilterType === 'FLEXIBLE' && <FlexibleDate />}
          </div>
        </>
      }
      isVisible={!readOnly && isDropdownVisible}
    />
  );
};

export default DateFilter;
