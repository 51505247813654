import React, { FC, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { Button, Alert } from '@wunder/ui-components';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { ApiError } from '@/core/types';
import { useRequestResetPasswordMutation } from '@/features/auth/services/Auth.service';
import { passwordSchema } from '@/features/auth/utils/validations/password.schema';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import PasswordChecker from '@/core/components/UI/PasswordChecker';

export interface ResetPasswordFormT {
  onPasswordChangedSuccessfully: () => void;
}

const ResetPasswordForm: FC<ResetPasswordFormT> = ({ onPasswordChangedSuccessfully }) => {
  const { token } = useParams();
  const [requestPassword, { error }] = useRequestResetPasswordMutation();
  useLingui();

  const onResetPasswordSubmit = ({ newPassword }: { newPassword: string }) =>
    requestPassword({ token: token!, newPassword })
      .unwrap()
      .then(() => {
        onPasswordChangedSuccessfully();
      });

  const resetPasswordSchema = useMemo(
    () =>
      yup.object().shape({
        newPassword: passwordSchema,
      }),
    [],
  );

  return (
    <Formik
      initialValues={{ newPassword: '' }}
      validationSchema={resetPasswordSchema}
      onSubmit={onResetPasswordSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            {!!error && (
              <Alert
                full
                type="error"
                title={t({
                  id: 'error',
                  message: 'Error',
                })}
                description={(error as ApiError).userMessage!}
              />
            )}

            <PasswordChecker
              fieldProps={{
                iconLeft: { icon: 'lockFilled', color: 'icon-600' },
                label: t({
                  id: 'auth.resetPassword.newPasswordLabel',
                  message: 'New password',
                }),
              }}
              fieldAttr={{
                placeholder: t({
                  id: 'auth.resetPassword.newPasswordPlaceholder',
                  message: 'New password',
                }),
                autoComplete: 'new-password',
              }}
              name="newPassword"
            />

            <Button
              loading={isSubmitting}
              size="large"
              width="full"
              attributes={{ type: 'submit' }}
            >
              <Trans id="auth.resetPassword.action">Send</Trans>
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
