import React, { FC } from 'react';
import { Button, Column, Paragraph, Row } from '@wunder/ui-components';
import { useFormikContext } from 'formik';
import { BookingExtended, BookingOverviewForm, StepRules } from '@/features/booking/types';
import { format } from 'date-fns';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { Link } from 'react-router';
import styles from '../../../../assets/scss/components/BookingFlow/BookingCompleted.module.scss';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import Banner from '@/core/components/Banner';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';
import { useRequestVehicleCategorySingleQuery } from '@/features/vehicles/services/Vehicles.service';

interface BookingCompletedT extends Omit<BookingFlowIDs, 'branchId'> {
  booking: BookingExtended;
  onBookingCompleted: () => void;
  rules: StepRules['thankYou'];
}

const BookingCompleted: FC<BookingCompletedT> = ({
  booking,
  bookingId,
  onBookingCompleted,
  rules,
}) => {
  const { values } = useFormikContext<BookingOverviewForm>();

  const { data: vehicleCategory } = useRequestVehicleCategorySingleQuery(
    values.selectedVehicleCategory!,
  );
  const dateLocale = useDateLocale();

  return (
    <Banner
      icon="checkmark"
      header={`${
        !bookingId
          ? t({ id: 'bookings.create.confirmation', message: 'Booking confirmation for:' })
          : t({ id: 'bookings.update.confirmation', message: 'Booking updated for:' })
      } ${vehicleCategory?.name}`}
      iconColorMode="stroke"
      iconColor="stroke-600"
      message={
        <>
          <Column marginBottom={24} sm={12}>
            <Paragraph align="center" textColor="text-400">
              {format(
                mergeDateTime(values.bookingDate?.from!, values.bookingTime?.from),
                DateFormats.FULL_DATE_TIME,
                {
                  locale: dateLocale,
                },
              )}{' '}
              -{' '}
              {format(
                mergeDateTime(
                  values.bookingDate?.to ?? values.bookingDate?.from!,
                  values.bookingTime?.to,
                ),
                DateFormats.FULL_DATE_TIME,
                {
                  locale: dateLocale,
                },
              )}
            </Paragraph>
          </Column>
          {booking.cancellationInformation?.canCancel &&
            booking.cancellationInformation?.freeUntil &&
            !rules?.hideFreeCancellationInfo && (
              <Column sm={12}>
                <Paragraph marginBottom={24} size={4} align="center" textColor="text-400">
                  <Trans id="booking.create.cancelFreeUntil">
                    You can <Link to="/my-trips/upcoming">cancel your booking</Link> free of charge
                    until{' '}
                    {format(
                      new Date(booking.cancellationInformation.freeUntil),
                      DateFormats.FULL_DATE_TIME,
                      { locale: dateLocale },
                    )}
                  </Trans>
                </Paragraph>
              </Column>
            )}
          <Column sm={12}>
            <Row justify="center">
              <Button className={styles['booking-completed__button']} onClick={onBookingCompleted}>
                <Trans id="general.ok">Ok</Trans>
              </Button>
            </Row>
          </Column>
        </>
      }
    />
  );
};

export default BookingCompleted;
