import React, { useEffect } from 'react';
import BookingLayout from '@/core/components/Layouts/Booking';
import Header from '@/features/booking/components/Header';
import WunderMaps from '@/features/geomap/components/WunderMaps';
import styles from '@/core/assets/scss/pages/Booking.module.scss';
import ZoomControls from '@/features/geomap/components/ZoomControls';
import Stations from '@/features/geomap/components/Stations';
import { Navigate, Outlet, useNavigate } from 'react-router';
import { useRequestAllBranchesQuery } from '@/features/branches/services/Branches.service';
import { Button, Column, Loader, NotificationStore, Paragraph, Row } from '@wunder/ui-components';
import CompleteProfile from '@/features/account/components/Onboarding/CompleteProfile';
import { useTypedSelector } from '@/core/redux/hooks';
import { getAllStepsCompleted, getMeData } from '@/features/account/redux/account.selectors';
import { accountChildRoutes } from '@/core/pages/Account/routes';
import { useStepStatus } from '@/features/account/hooks/useStepStatus';
import { LocalStorageKeys } from '@/core/enums';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { useAccountProfileComplete } from '@/features/account/hooks/useAccountProfileComplete';
import cn from 'classnames';
import BookingResults from '@/features/booking/components/Results/BookingResults';

const Booking = () => {
  const { isFetching, isError: errorFetchingBranches } = useRequestAllBranchesQuery();
  const meData = useTypedSelector(getMeData);
  const allStepsCompleted = useTypedSelector(getAllStepsCompleted);
  const stepsStatus = useStepStatus();
  const accountSetupSkipped = meData?.customProperties?.accountSetupSkipped === true;
  const navigate = useNavigate();
  const accountCompleted = useAccountProfileComplete();

  useEffect(() => {
    const businessAccountJoinRequestCode = localStorage.getItem(
      LocalStorageKeys.JOIN_APPLICATION_CODE,
    );
    const joinRequestData =
      businessAccountJoinRequestCode && JSON.parse(businessAccountJoinRequestCode);

    if (joinRequestData) {
      NotificationStore.addNotification({
        id: 'joinRequestInfo',
        type: 'info',
        title: t({
          id: 'businessAccount.joinApplicationInfo.title',
          message: 'Join business account',
        }),
        content: (
          <Row>
            <Column sm={12}>
              <Paragraph size={4}>
                <Trans id="businessAccount.joinApplicationInfo.description">
                  Join business account application code expired and we were unable to send it. Do
                  you wish to resend the invitation?
                </Trans>
              </Paragraph>
            </Column>
            <Column sm={12}>
              <Row gapSm="xs">
                <Column>
                  <Button
                    onClick={() => {
                      navigate(`/public/join-application/${joinRequestData.code}`);
                      NotificationStore.removeNotification('joinRequestInfo');
                    }}
                    variation="secondary"
                    size="small"
                  >
                    <Trans id="businessAccount.joinApplication.resend">Resend invitation</Trans>
                  </Button>
                </Column>
                <Column>
                  <Button
                    onClick={() => {
                      NotificationStore.removeNotification('joinRequestInfo');
                      localStorage.removeItem(LocalStorageKeys.JOIN_APPLICATION_CODE);
                    }}
                    variation="secondary"
                    size="small"
                  >
                    <Trans id="general.ignore">Ignore</Trans>
                  </Button>
                </Column>
              </Row>
            </Column>
          </Row>
        ),
        duration: 100000,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errorFetchingBranches) {
    return (
      <ErrorFallback
        message={
          <Trans id="errorFallback.dataFailedToLoad">Data failed to load. Try again later.</Trans>
        }
      />
    );
  }

  if (isFetching) {
    return <Loader cover />;
  }

  if (meData && !accountSetupSkipped && !allStepsCompleted) {
    return <Navigate to={accountChildRoutes[stepsStatus.completed].path} />;
  }

  return (
    <BookingLayout className={styles['bookings__with-filters']} header={<Header />}>
      <div className={styles.bookings__map}>
        <WunderMaps>
          <Row noWrap className={styles['bookings__results-container']} column>
            <BookingResults />
            <CompleteProfile />
          </Row>

          <ZoomControls
            className={cn(!accountCompleted && styles['bookings__map-uncompleted-account'])}
          />
          <Stations />
          <Outlet />
        </WunderMaps>
      </div>
    </BookingLayout>
  );
};

export default Booking;
