import React, { FC } from 'react';
import { ConfirmationModal } from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { format, isBefore } from 'date-fns';
import { DateFormats } from '@/core/enums';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';
import { ProvidedModal } from '@/core/components/UI/modals/ModalProvider';
import { useRequestBookingCancelMutation } from '@/features/booking/services/Booking.service';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { BookingExtended } from '@/features/booking/types';

export interface CancelBookingT extends ProvidedModal {
  booking: BookingExtended;
  currency?: string;
}

const CancelBooking: FC<CancelBookingT> = ({ onCancel, booking, currency }) => {
  const [cancelBooking] = useRequestBookingCancelMutation();
  const locale = useDateLocale();

  return (
    <ConfirmationModal
      type="primary"
      onConfirm={() => cancelBooking(booking.id)}
      onCancel={() => onCancel?.()}
      title={<Trans id="myTrip.cancelModal.title">Are you sure you want to cancel?</Trans>}
      deleteMessage={
        <>
          <Trans id="myTrip.cancelModal.message">Your vehicle booking will be canceled.</Trans>
          {!!booking?.cancellationInformation && booking.cancellationInformation.canCancel && (
            <span data-testid="CancelationInformation">
              <br />
              <br />
              {isBefore(new Date(), new Date(booking.cancellationInformation.freeUntil)) ? (
                <Trans id="myTrip.cancelModal.freeCancelationUntil">
                  Free cancellation until:{' '}
                  {format(
                    new Date(booking.cancellationInformation.freeUntil),
                    DateFormats.FULL_DATE_TIME,
                    { locale },
                  )}
                  <br />
                </Trans>
              ) : (
                <Trans id="myTrip.cancelModal.cancelationEstimation">
                  Estimated cancellation fee:{' '}
                  <CurrencyDisplay
                    value={booking.cancellationInformation.estimatedFee}
                    currency={currency ?? booking.pricing?.currency!}
                  />
                </Trans>
              )}
            </span>
          )}
        </>
      }
      opened
    />
  );
};

export default CancelBooking;
