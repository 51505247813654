import React from 'react';
import { Loader, Modal } from '@wunder/ui-components';
import { useGoBack } from '@/core/hooks/useGoBack';
import { Trans } from '@lingui/react/macro';
import { FormikProvider, useFormik, Form } from 'formik';
import TripDrivingLogFormFields from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogFormFields';
import { DrivingLogCreateForm } from '@/features/myTrips/types/DrivingLog';
import TripDrivingLogModalActions from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogModalActions';
import { drivingLogSchema } from '@/features/myTrips/utils/validations/drivingLog.schema';
import { useRequestCreateDrivingLogEntryMutation } from '@/features/myTrips/services/DrivingLog.service';
import { useOutletContext } from 'react-router';
import { BookingExtended } from '@/features/booking/types';
import { format } from 'date-fns';

const TripDrivingLogCreate = () => {
  const goBack = useGoBack();
  const tripData = useOutletContext<BookingExtended>();
  const [createDrivingLogEntry] = useRequestCreateDrivingLogEntryMutation();

  const onSubmit = async (values: DrivingLogCreateForm) => {
    return createDrivingLogEntry({
      ...values,
      startDate: format(new Date(values.startDate!), 'yyyy-MM-dd'),
      rentalId: tripData.rentalId!,
    })
      .unwrap()
      .then(() => {
        goBack();
      });
  };

  const form = useFormik<DrivingLogCreateForm>({
    initialValues: {
      startDate: undefined,
      startLocation: undefined,
      endLocation: undefined,
      reason: undefined,
      startKilometers: undefined,
      endKilometers: undefined,
      note: undefined,
      tripType: undefined,
    },
    validationSchema: drivingLogSchema,
    onSubmit,
  });

  return (
    <FormikProvider value={form}>
      <Modal
        modalHeader={<Trans id="myTrips.tripDrivingLogCreate.title">Create driving log</Trans>}
        modalFooter={<TripDrivingLogModalActions mode="create" />}
        variation="small"
        onClose={goBack}
        opened
      >
        <Form>
          {form.isSubmitting && <Loader cover />}
          <TripDrivingLogFormFields />
        </Form>
      </Modal>
    </FormikProvider>
  );
};

export default TripDrivingLogCreate;
