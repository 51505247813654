import React, { AnchorHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ParagraphComponent } from '@wunder/ui-components';
import styles from '@/core/assets/scss/components/MarkdownParser.module.scss';
import cn from 'classnames';

interface MarkdownParserT {
  markdown: string;
  size?: ParagraphComponent['size'];
  color?: ParagraphComponent['textColor'];
  marginBottom?: number;
}

const LinkRenderer: FC<
  PropsWithChildren<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>
> = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

const MarkdownParser: FC<MarkdownParserT> = ({ markdown, size = 3, color, marginBottom }) => {
  return (
    <div
      className={cn(styles['markdown-parser'], styles[`markdown-parser--${size}`])}
      style={{ color, marginBottom }}
    >
      <Markdown components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
        {markdown}
      </Markdown>
    </div>
  );
};

export default MarkdownParser;
