import { useEffect, useState } from 'react';
import { useMap } from '@wunder/ui-components';

export const useIsMapLoaded = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useMap();

  useEffect(() => {
    if (map?.current) {
      const mapInstance = map.current;
      const onMapLoad = () => {
        setMapLoaded(true);
      };

      mapInstance.on('load', onMapLoad);

      return () => {
        mapInstance.off('load', onMapLoad);
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return mapLoaded;
};
