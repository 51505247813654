import React, { useState } from 'react';
import BusinessAccountCreateLayout from '@/features/businessAccounts/components/Create/Layout';
import { Button, Loader, Row } from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { useNavigate } from 'react-router';
import { useRequestBusinessAccountQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import PaymentStep from '@/features/businessAccounts/components/Create/PaymentStep';
import ResponsiveFixedButtons from '@/core/components/UI/ResponsiveFixedButtons';

const Payment = () => {
  const [paymentAdded, setPaymentAdded] = useState(false);
  const navigate = useNavigate();
  const { data: businessAccountData, isLoading } = useRequestBusinessAccountQuery();

  if (isLoading) {
    return <Loader cover />;
  }

  return (
    <BusinessAccountCreateLayout
      actions={
        <ResponsiveFixedButtons>
          {!paymentAdded && (
            <Button
              variation="secondary"
              attributes={{ role: 'link' }}
              onClick={() => navigate('/business-account/team/members')}
            >
              <Trans id="general.skip">Skip</Trans>
            </Button>
          )}
          <Button
            onClick={() => navigate('/business-account/team/members')}
            disabled={!paymentAdded}
          >
            <Trans id="general.next">Next</Trans>
          </Button>
        </ResponsiveFixedButtons>
      }
    >
      <Row justify="center">
        <PaymentStep
          onPaymentAdded={() => setPaymentAdded(true)}
          businessAccount={businessAccountData!}
        />
      </Row>
    </BusinessAccountCreateLayout>
  );
};

export default Payment;
