import React from 'react';
import { NavLink } from 'react-router';
import cn from 'classnames';
import { Svg } from '@wunder/ui-components';
import cssModules from '@/core/assets/scss/components/Dashboard/MainMenu.module.scss';
import { NavigationItemT } from '@/core/types';
import useNavigationLinkState from '@/core/hooks/useNavigationLinkState';

const MainMenuItem: React.FC<NavigationItemT> = ({ to, href, content, icon, additionalPaths }) => {
  const { active } = useNavigationLinkState(to, additionalPaths);

  const linkContent = (
    <>
      <Svg icon={icon} className={cssModules['main-navigation__item__icon']} />
      <span className={cssModules['main-navigation__item__content']}>{content} </span>
    </>
  );

  return (
    <li className={cssModules['main-navigation__item']}>
      {href && (
        <a
          className={cssModules['main-navigation__item__link']}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {linkContent}
        </a>
      )}

      {to && (
        <NavLink
          className={cn([
            cssModules['main-navigation__item__link'],
            active && cssModules['main-navigation__item__link--active'],
          ])}
          to={to}
        >
          {linkContent}
        </NavLink>
      )}
    </li>
  );
};

export default MainMenuItem;
