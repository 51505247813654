import React, { FC, useMemo, useRef } from 'react';
import { Flex, Loader, NoResults, Paragraph, Separator, Show } from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { useNavigate, useParams } from 'react-router';
import SkeletonLoader from '@/core/components/SkeletonLoader';
import { useTypedSelector } from '@/core/redux/hooks';
import { Sheet, SheetRef } from 'react-modal-sheet';
import {
  getFilteredStationsData,
  getIsFetchingFilteredStationsData,
} from '@/features/account/redux/account.selectors';
import VehicleCategoryResultsCard from '@/features/booking/components/VehicleCategoryResults/VehicleCategoryResultsCard';
import styles from '@/features/booking/assets/scss/components/Results/BookingResults.module.scss';
import { useDelayedUpdate } from '@/core/hooks/useDelayedUpdate';
import cn from 'classnames';
import { AvailableBookingStations } from '@/features/booking/types';
import { useResponsive } from '@/core/hooks/useResponsive';
import { useIsMapLoaded } from '@/features/geomap/hooks/useIsMapLoaded';

interface ResultsVehicleCategoriesT {
  isFetching: boolean;
  stations: AvailableBookingStations[];
  isResponsive?: boolean;
  onCategoryClick?: () => void;
  isUninitialized?: boolean;
}

interface BookingFilterResultsT {
  TEST_ONLY_forceRenderWithoutmap?: boolean; // Used only for testing this jsDOM cannot render map and we have issues with tests
}

const ResultsVehicleCategories: FC<ResultsVehicleCategoriesT> = ({
  isFetching,
  stations,
  isResponsive,
  onCategoryClick,
  isUninitialized,
}) => {
  const navigate = useNavigate();

  const numberOfAvailableVehicles = useMemo(() => {
    return stations.reduce((acc, station) => {
      const stationCategoriesWithAvailableVehicles =
        station?.availableCategories?.filter(
          (category) => !!category?.availableTimeSlots?.length,
        ) ?? 0;

      return acc + (stationCategoriesWithAvailableVehicles?.length ?? 0);
    }, 0);
  }, [stations]);

  return (
    <Flex
      direction="column"
      className={cn(
        styles['booking-results__container'],
        isResponsive && styles['booking-results__container--responsive'],
      )}
    >
      <Flex direction="column">
        {!isResponsive && (
          <Paragraph weight="medium" marginBottom={0} textColor="text-950">
            <Trans id="booking.filterResults.availableVehicles">
              {numberOfAvailableVehicles} available vehicles
            </Trans>
          </Paragraph>
        )}

        <Separator gapTop={isResponsive ? 0 : 24} gapBottom={32} backgroundColor="bg-200" />
      </Flex>
      {numberOfAvailableVehicles <= 0 && (
        <NoResults
          icon="car"
          title={t({ id: 'booking.filterResults.noResultsTitle', message: 'No results' })}
          description={
            <Paragraph size={3} textColor="text-400" align="center">
              <Trans id="booking.filterResults.noResultsDescription">
                Sorry, no vehicles available.
              </Trans>
            </Paragraph>
          }
        />
      )}

      {isUninitialized || isFetching ? (
        <SkeletonLoader numberOfContentRows={isResponsive ? 3 : 6} />
      ) : (
        <Show when={numberOfAvailableVehicles > 0}>
          <Flex direction="column" gap="xl">
            {stations?.map((station) => {
              return (
                <Flex direction="column" key={station.branch.id}>
                  <Paragraph weight="medium" marginBottom={16} textColor="text-950">
                    {station.branch.name}
                  </Paragraph>

                  {station.availableCategories?.map((category) => {
                    return (
                      <VehicleCategoryResultsCard
                        key={category.id}
                        onClick={(vehicleCategory) => {
                          if (onCategoryClick) onCategoryClick();
                          const searchParams = new URLSearchParams(window.location.search);

                          searchParams.set('vehicleCategoryId', `${vehicleCategory.id}`);

                          navigate(`/booking/${vehicleCategory.branch.id}?${searchParams}`);
                        }}
                        categoryData={{ ...category, branch: station.branch }}
                      />
                    );
                  })}
                </Flex>
              );
            })}
          </Flex>
        </Show>
      )}
    </Flex>
  );
};

const BookingFilterResults: FC<BookingFilterResultsT> = ({ TEST_ONLY_forceRenderWithoutmap }) => {
  const params = useParams();
  const isFetching = useTypedSelector(getIsFetchingFilteredStationsData);
  const stationCategoriesData = useTypedSelector(getFilteredStationsData);
  const isFetchingResults = useDelayedUpdate(isFetching, 0, 250);
  const { isMobile } = useResponsive();
  const sheetRef = useRef<SheetRef>();
  const mapLoaded = useIsMapLoaded();

  const filteredStations = useMemo<AvailableBookingStations[]>(() => {
    if (!stationCategoriesData) return [];

    return stationCategoriesData.filter((category) => !!category.availableCategories.length);
  }, [stationCategoriesData]);

  const numberOfAvailableVehicles = useMemo(() => {
    return (
      filteredStations?.reduce((acc, station) => {
        return acc + (station.availableCategories.length ?? 0);
      }, 0) ?? 0
    );
  }, [filteredStations]);

  // We need to make sure map is loaded before results are displayed
  if (!mapLoaded && !TEST_ONLY_forceRenderWithoutmap) {
    return null;
  }

  if (isMobile) {
    return (
      <Sheet
        ref={sheetRef}
        dragVelocityThreshold={100000000}
        className={styles['booking-results__sheet']}
        snapPoints={[0.6, 68]}
        initialSnap={1}
        isOpen
        onClose={() => {}}
      >
        <Sheet.Container>
          <Sheet.Header>
            <Flex
              className={styles['booking-results__responsive-header']}
              gap="sm"
              align="center"
              justify="center"
              direction="column"
            >
              <div className={styles['booking-results__drag']} />
              <Flex
                className={styles['booking-results__responsive-availability']}
                align="center"
                gap="sm"
              >
                <Paragraph align="center" weight="medium" marginBottom={0} textColor="text-950">
                  <Trans id="booking.filterResults.availableVehicles">
                    {numberOfAvailableVehicles} available vehicles
                  </Trans>
                </Paragraph>
                {isFetchingResults && (
                  <div className={styles['booking-results__responsive-loader']}>
                    <Loader width={18} height={18} />
                  </div>
                )}
              </Flex>
            </Flex>
          </Sheet.Header>
          <Sheet.Scroller>
            <Sheet.Content>
              <ResultsVehicleCategories
                isUninitialized={stationCategoriesData === null}
                onCategoryClick={() => sheetRef.current?.snapTo(1)}
                isResponsive
                isFetching={isFetchingResults}
                stations={filteredStations}
              />
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  }

  return (
    <Flex
      growChildren
      className={cn(
        styles['booking-results'],
        params?.branchId && styles['booking-results--hidden'],
      )}
    >
      <ResultsVehicleCategories
        isUninitialized={stationCategoriesData === null}
        isFetching={isFetchingResults}
        stations={filteredStations}
      />
    </Flex>
  );
};

export default BookingFilterResults;
