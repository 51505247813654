import React, { FC } from 'react';
import {
  Button,
  Column,
  Row,
  Svg,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  Tags,
  Tooltip,
} from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { format } from 'date-fns';
import { DateFormats } from '@/core/enums';
import { lowerCase, upperFirst } from 'lodash';
import { i18n } from '@lingui/core';
import { RentalDrivingLog } from '@/features/myTrips/types/DrivingLog';
import styles from '@/features/myTrips/assets/scss/components/TripDrivingLog/TripDrivingLogEntriesTable.module.scss';

interface TripDrivingLogEntriesTableT {
  isDrivingLogSubmitted: boolean;
  drivingLog: RentalDrivingLog;
  onRequestEdit: (id: number) => void;
  onRequestDelete: (id: number) => void;
  isReadOnly?: boolean;
}

const TripDrivingLogEntriesTable: FC<TripDrivingLogEntriesTableT> = ({
  isDrivingLogSubmitted,
  drivingLog,
  onRequestDelete,
  onRequestEdit,
  isReadOnly,
}) => {
  return (
    <Table>
      <TableRow>
        {!isDrivingLogSubmitted && !isReadOnly && <TableHeaderCell />}
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.date">Date</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.from">From</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.to">To</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.reason">Reason</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.tripType">Trip type</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.odometerStart">Odometer start</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.odometerEnd">Odometer end</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.distance">Distance</Trans>
        </TableHeaderCell>
        <TableHeaderCell>
          <Trans id="myTrips.drivingLog.table.notes">Notes</Trans>
        </TableHeaderCell>
        {!isDrivingLogSubmitted && !isReadOnly && <TableHeaderCell />}
      </TableRow>

      {drivingLog?.entries?.map((drivingLogEntry) => {
        const drivingLogErrors =
          drivingLog?.validationResult?.entriesWithErrors[drivingLogEntry.id];

        return (
          <TableRow
            onClick={
              !isDrivingLogSubmitted && !isReadOnly
                ? () => onRequestEdit(drivingLogEntry.id)
                : undefined
            }
            key={drivingLogEntry.id}
          >
            {!isReadOnly && !isDrivingLogSubmitted && (
              <TableCell>
                {drivingLogErrors && (
                  <Column>
                    <Tooltip
                      placement="bottom"
                      content={
                        <ul className={styles['trip-driving-log-table__list']}>
                          {drivingLogErrors.map((drivingLogError) => (
                            <li key={drivingLogError.field}>
                              {drivingLogError.errorMessage} <br />
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <span data-testid="DrivingLogErrors">
                        <Svg width={20} icon="issues" color="warning" />
                      </span>
                    </Tooltip>
                  </Column>
                )}
              </TableCell>
            )}

            <TableCell>
              {format(new Date(drivingLogEntry.startDate), DateFormats.SHORT_DATE)}
            </TableCell>
            <TableCell>{drivingLogEntry.startLocation ?? '-'}</TableCell>
            <TableCell>{drivingLogEntry.endLocation ?? '-'}</TableCell>
            <TableCell>{drivingLogEntry.reason ?? '-'}</TableCell>
            <TableCell>
              {drivingLogEntry.tripType ? (
                <Tags size="small" content={upperFirst(lowerCase(drivingLogEntry.tripType))} />
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              {drivingLogEntry.startKilometers
                ? i18n.number(drivingLogEntry.startKilometers, {
                    unit: 'kilometer',
                    unitDisplay: 'short',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'unit',
                  })
                : '-'}
            </TableCell>
            <TableCell>
              {drivingLogEntry.endKilometers
                ? i18n.number(drivingLogEntry.endKilometers, {
                    unit: 'kilometer',
                    unitDisplay: 'short',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'unit',
                  })
                : '-'}
            </TableCell>
            <TableCell>
              {drivingLogEntry.endKilometers && drivingLogEntry.startKilometers
                ? i18n.number(drivingLogEntry.endKilometers - drivingLogEntry.startKilometers, {
                    unit: 'kilometer',
                    unitDisplay: 'short',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'unit',
                  })
                : '-'}
            </TableCell>
            <TableCell>{drivingLogEntry.note ?? '-'}</TableCell>
            {!isDrivingLogSubmitted && !isReadOnly && (
              <TableCell>
                <Row noWrap alignItems="flex-start" gapSm="sm">
                  <Column>
                    <Tooltip content={<Trans id="general.edit">Edit</Trans>}>
                      <Button
                        iconName="editFilled"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRequestEdit(drivingLogEntry.id);
                        }}
                        iconSize={{ width: 14 }}
                        noPadding
                        variation="link"
                        attributes={{ role: 'button', 'aria-label': 'Edit driving log entry' }}
                      />
                    </Tooltip>
                  </Column>
                  <Column>
                    <Tooltip content={<Trans id="general.remove">Remove</Trans>}>
                      <Button
                        iconSize={{ width: 14 }}
                        iconName="trashFilled"
                        noPadding
                        variation="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRequestDelete(drivingLogEntry.id);
                        }}
                        attributes={{
                          role: 'button',
                          'aria-label': 'Remove driving log entry',
                        }}
                      />
                    </Tooltip>
                  </Column>
                </Row>
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </Table>
  );
};

export default TripDrivingLogEntriesTable;
