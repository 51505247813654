import React, { useMemo, FC } from 'react';
import { Column, Paragraph, Row, Separator, Alert, Loader } from '@wunder/ui-components';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryAdditions } from '@/features/vehicles/redux/Vehicles.selector';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { VehicleCategory } from '@/features/vehicles/types';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import AdditionPriceBreakdown from '@/features/booking/components/BookingFlow/components/AdditionsSelection/AdditionPriceBreakdown';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';
import UnitDisplay from '@/core/components/UnitDisplay';
import { useLingui } from '@lingui/react';
import { getNormalizedUnit } from '@/core/utils/getNormalizedUnit';
import styles from '@/features/booking/assets/scss/components/PricingBreakdown.module.scss';
import { useRequestBookingEstimatedPricingQuery } from '@/core/features/booking/services/Booking.service';
import { mergeDateTime } from '@/core/features/booking/utils/mergeDateTime';

interface PricingBreakdownT {
  vehicleCategory: VehicleCategory;
  branchId: string;
}

const PricingBreakdown: FC<PricingBreakdownT> = ({ vehicleCategory, branchId }) => {
  const { values } = useFormikContext<BookingOverviewForm>();
  const additionsData = useTypedSelector(getVehicleCategoryAdditions);
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { showGrossPrice } = bookingSettings?.value ?? {};
  const { i18n } = useLingui();

  const {
    data: estimatedPrice,
    isFetching: isFetchingPriceEstimation,
    error: isEstimatedPriceError,
  } = useRequestBookingEstimatedPricingQuery({
    branchId: +branchId,
    startTime: mergeDateTime(values.bookingDate!.from!, values.bookingTime?.from).toISOString(),
    endTime: mergeDateTime(
      values.bookingDate!.to ?? values.bookingDate!.from!,
      values.bookingTime?.to,
    ).toISOString(),
    additions: values.selectedAdditions?.map((addition) => addition) ?? [],
    vehicleCategoryId: values.selectedVehicleCategory!,
  });

  const additionsPriceMap = useMemo(() => {
    return (
      estimatedPrice?.additions?.reduce(
        (acc, addition) => {
          acc[addition.code] = addition.price;
          return acc;
        },
        {} as Record<string, number>,
      ) ?? {}
    );
  }, [estimatedPrice]);

  const selectedAdditions = useMemo(() => {
    const selected =
      values.selectedAdditions && additionsData
        ? values.selectedAdditions
            .map((code) => additionsData.find((addition) => addition.code === code))
            .filter(Boolean)
        : [];
    return [...selected];
  }, [additionsData, values.selectedAdditions]);

  const totalPrice = useMemo(() => {
    if (!vehicleCategory || !estimatedPrice) return { net: 0, gross: 0 };

    return {
      gross: estimatedPrice.grossPrice,
      net: estimatedPrice.netPrice,
    };
  }, [vehicleCategory, estimatedPrice]);

  if (!vehicleCategory || isEstimatedPriceError) return null;

  if (isFetchingPriceEstimation) return <Loader />;

  return (
    <>
      <Row justify="space-between">
        <Column>
          <Paragraph marginBottom={0} weight="medium">
            <Trans id="bookings.createSummary.rentalPrice">Rental price</Trans>
          </Paragraph>
        </Column>
        <Column>
          <Paragraph marginBottom={0} weight="medium">
            <CurrencyDisplay
              value={
                showGrossPrice
                  ? estimatedPrice!.pricing.timePrice!.timeTotalGross
                  : estimatedPrice!.pricing.timePrice!.timeTotalNet
              }
              currency={estimatedPrice!.currency}
            />
          </Paragraph>
        </Column>
      </Row>
      {selectedAdditions.map((addition) => {
        const estimatedTotal = additionsPriceMap[addition!.code];

        return (
          <Row key={addition!.code} justify="space-between">
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                {addition!.name}
              </Paragraph>
            </Column>
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                <AdditionPriceBreakdown addition={addition!} currency={estimatedPrice!.currency} />
                <CurrencyDisplay value={estimatedTotal} currency={estimatedPrice!.currency} />
              </Paragraph>
            </Column>
          </Row>
        );
      })}
      {!showGrossPrice && (
        <>
          <Separator gapBottom={16} gapTop={8} />
          <Row justify="space-between" marginBottom={16}>
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                <Trans id="bookings.createSummary.subTotal">Subtotal</Trans>
              </Paragraph>
            </Column>
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                <CurrencyDisplay value={totalPrice.net} currency={estimatedPrice!.currency} />
              </Paragraph>
            </Column>
          </Row>

          <Row justify="space-between" marginBottom={16}>
            <Column>
              <Paragraph size={2} marginBottom={0} weight="medium">
                <Trans id="bookings.createSummary.VAT">VAT</Trans>
              </Paragraph>
            </Column>
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                <CurrencyDisplay
                  value={totalPrice.gross - totalPrice.net}
                  currency={estimatedPrice!.currency}
                />
              </Paragraph>
            </Column>
          </Row>
        </>
      )}
      <Separator gapBottom={16} gapTop={8} />
      <Row justify="space-between" marginBottom={16}>
        <Column>
          <Paragraph size={2} marginBottom={0} weight="bold">
            <Trans id="bookings.createSummary.totalPrice">Total price</Trans>
            <span className={styles['pricing-breakdown__tax']}>
              (<Trans id="general.includeTaxShort">Incl. VAT</Trans>)
            </span>
          </Paragraph>
        </Column>
        <Column>
          <Paragraph marginBottom={0} weight="bold">
            <CurrencyDisplay value={totalPrice.gross} currency={estimatedPrice!.currency} />
          </Paragraph>
        </Column>
      </Row>
      {estimatedPrice!.pricing?.distancePrice?.rate?.rate && (
        <Alert
          type="info"
          canBeClosed={false}
          full
          className={styles['pricing-breakdown__distance']}
          icon="info"
          title={t({ id: 'bookings.createSummary.distancePrice', message: 'Distance price' })}
          description={
            <>
              {estimatedPrice!.pricing?.distancePrice?.rate?.included ? (
                <Trans id="bookings.createSummary.distancePriceTooltip">
                  After the included distance of{' '}
                  {i18n.number(estimatedPrice!.pricing.distancePrice.rate.included, {
                    unit: getNormalizedUnit(estimatedPrice!.pricing.distancePrice.unit),
                    style: 'unit',
                    unitDisplay: 'short',
                  })}
                  , a rate of{' '}
                  <CurrencyDisplay
                    value={estimatedPrice!.pricing?.distancePrice?.rate?.rate}
                    currency={estimatedPrice!.currency}
                  />
                  <UnitDisplay
                    unit={estimatedPrice!.pricing?.distancePrice?.unit}
                    unitDisplay="shortPer"
                  />{' '}
                  applies.
                </Trans>
              ) : (
                <>
                  <CurrencyDisplay
                    value={estimatedPrice!.pricing?.distancePrice?.rate?.rate}
                    currency={estimatedPrice!.currency}
                  />
                  <UnitDisplay
                    unit={estimatedPrice!.pricing?.distancePrice?.unit}
                    unitDisplay="shortPer"
                  />
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default PricingBreakdown;
