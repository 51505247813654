import { FC, KeyboardEventHandler, useEffect, useRef } from 'react';
import { Number, Row } from '@wunder/ui-components';
import styles from '@/features/auth/assets/scss/PhoneLogin.module.scss';
import { useFormikContext } from 'formik';
import { isNaN } from 'lodash';

interface CodeInputFormValues {
  firstDigit: string;
  secondDigit: string;
  thirdDigit: string;
  fourthDigit: string;
  [key: string]: string;
}

interface CodeInputProps {
  onFull: () => void;
}
const CodeInput: FC<CodeInputProps> = ({ onFull }) => {
  const digitFields = ['firstDigit', 'secondDigit', 'thirdDigit', 'fourthDigit'];
  const { setFieldValue, values } = useFormikContext<CodeInputFormValues>();
  const codeRef = useRef('');
  const focusInput = (fieldId: string) => {
    document.getElementById(fieldId)?.focus();
  };

  useEffect(() => {
    focusInput(digitFields[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const code = digitFields.map((field) => values[field]).join('');
    if (code.length === 4 && codeRef.current !== code) {
      codeRef.current = code;
      setFieldValue('code', code);
      onFull();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleInput = (fieldId: string) => (code: number) => {
    if (code === undefined || isNaN(code)) {
      setFieldValue(fieldId, undefined);
      return;
    }
    const codeString = String(code);
    if (codeString.length === 1) {
      const index = digitFields.findIndex((field) => field === fieldId);
      focusInput(digitFields[index + 1]);
    } else if (fieldId === 'firstDigit' && codeString.length === 4) {
      const digitList = codeString.slice(0, 4).split('');
      digitList.forEach((digit, index) => {
        setFieldValue(digitFields[index], digit);
      });
    } else {
      const value = codeString.slice(0, 1);
      setFieldValue(fieldId, value);
    }
  };

  const handleKeyDown =
    (fieldId: string): KeyboardEventHandler<HTMLInputElement> =>
    (event) => {
      if (event.key === 'Backspace') {
        const index = digitFields.findIndex((field) => field === fieldId);
        setFieldValue(fieldId, undefined);
        if (index > 0) {
          focusInput(digitFields[index - 1]);
        }
      }
    };

  return (
    <Row marginBottom={0} gapMd="xs" gapSm="xs" justify="center">
      <Number
        fieldAttr={{
          id: digitFields[0],
          className: styles['phone-login__digit'],
          onKeyDown: handleKeyDown(digitFields[0]),
        }}
        fieldProps={{
          onChange: handleInput(digitFields[0]),
          integersOnly: true,
        }}
        name={digitFields[0]}
      />
      <Number
        fieldAttr={{
          id: digitFields[1],
          className: styles['phone-login__digit'],
          onKeyDown: handleKeyDown(digitFields[1]),
        }}
        fieldProps={{
          onChange: handleInput(digitFields[1]),
          integersOnly: true,
        }}
        name={digitFields[1]}
      />
      <Number
        fieldAttr={{
          id: digitFields[2],
          className: styles['phone-login__digit'],
          onKeyDown: handleKeyDown(digitFields[2]),
        }}
        fieldProps={{
          onChange: handleInput(digitFields[2]),
          integersOnly: true,
        }}
        name={digitFields[2]}
      />
      <Number
        fieldAttr={{
          id: digitFields[3],
          className: styles['phone-login__digit'],
          onKeyDown: handleKeyDown(digitFields[3]),
        }}
        fieldProps={{
          onChange: handleInput(digitFields[3]),
          integersOnly: true,
        }}
        name={digitFields[3]}
      />
    </Row>
  );
};

export default CodeInput;
