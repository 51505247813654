import { FC, useEffect, useMemo } from 'react';
import { Card, Flex, Paragraph, Svg, useMap } from '@wunder/ui-components';
import { VehicleCategoryResultsWithBranch } from '@/features/vehicles/types';
import styles from '../../assets/scss/components/VehicleCategoryResults/VehicleCategoryResultsCard.module.scss';
import VehicleCategoryEquipmentTags from '../VehicleCategoryEquipmentTags';
import cn from 'classnames';
import VehicleCategoryTimeslotsTags from '@/features/booking/components/VehicleCategoryTimeslotsTags';
import VehicleCategoryPricingDisplay from '@/features/vehicles/components/VehicleCategoryPricingDisplay';
import Image from '@/core/components/UI/Image';
import { useTypedSelector } from '@/core/redux/hooks';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';

interface VehicleCategoryResultsCardT {
  categoryData: VehicleCategoryResultsWithBranch;
  onClick: (category: VehicleCategoryResultsWithBranch) => void;
}

const VehicleCategoryResultsCard: FC<VehicleCategoryResultsCardT> = ({ categoryData, onClick }) => {
  const mapInstance = useMap();
  const activeFilters = useTypedSelector(getActiveFilterValues);

  const mapCanvas = useMemo(() => {
    if (!mapInstance.current) {
      return null;
    }

    const map = mapInstance.current.getMap();

    if (!map.isStyleLoaded()) {
      return null;
    }

    const feature = mapInstance.current.querySourceFeatures('stations', {
      filter: ['==', 'id', categoryData.branch.id],
    })?.[0];

    return {
      feature,
      map,
    };
  }, [mapInstance, categoryData]);

  useEffect(() => {
    return () => {
      if (mapCanvas?.map?.setLayoutProperty && mapCanvas?.map?.isStyleLoaded()) {
        mapCanvas.map.setLayoutProperty('pin', 'icon-size', [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0.2,
          18,
          0.7,
        ]);
      }
    };
  }, [mapCanvas]);

  const hasDateRelatedFilters = !!activeFilters?.flexibleDurationType || !!activeFilters?.startTime;

  if (!categoryData?.availableTimeSlots?.length) {
    return null;
  }

  return (
    <Card
      onMouseEnter={() => {
        if (!categoryData.branch.position?.coordinates || !mapCanvas) return;

        if (mapCanvas.feature) {
          mapCanvas.map.setLayoutProperty('pin', 'icon-size', [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0.2,
            18,
            ['case', ['==', ['get', 'id'], categoryData.branch.id], 1, 0.7],
          ]);
        }
      }}
      onMouseLeave={() => {
        if (!categoryData.branch.position?.coordinates || !mapCanvas) return;

        if (mapCanvas.feature) {
          mapCanvas.map.setLayoutProperty('pin', 'icon-size', [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0.2,
            18,
            0.7,
          ]);
        }
      }}
      onClick={() => {
        // Need to have this condition because of tests since map cannot be loaded there
        if (mapInstance.current) {
          mapInstance.current.flyTo({
            center: [
              categoryData.branch.position?.coordinates[0],
              categoryData.branch.position?.coordinates[1],
            ],
            duration: 1000,
            zoom: 16,
          });
        }
        onClick(categoryData);
      }}
      className={cn(styles['category-card'])}
    >
      <Flex
        gap="md"
        width="full"
        className={cn(styles['category-card__content'])}
        direction="column"
      >
        <Flex width="full" gap="md" justify="space-between">
          <Flex align="flex-start" className={styles['category-card__image']}>
            <Image
              width={120}
              height={100}
              src={categoryData?.appProperties?.image}
              fallback={<Svg color="icon-600" icon="car" />}
              alt={categoryData.name}
            />
          </Flex>
          <Flex gap="xs" direction="column" className={styles['category-card__description']}>
            <Paragraph marginBottom={0} weight="medium">
              {categoryData.name}
            </Paragraph>
            <Paragraph marginBottom={0} textColor="text-400" size={3}>
              <VehicleCategoryPricingDisplay
                extendedPricing={categoryData?.pricing}
                grossPrice={categoryData.grossPrice}
                netPrice={categoryData.netPrice}
                currency={categoryData.currency}
              />
            </Paragraph>
            <Flex>
              {categoryData?.vehicleAttributes && (
                <VehicleCategoryEquipmentTags limit={4} features={categoryData.vehicleAttributes} />
              )}
            </Flex>
          </Flex>
        </Flex>

        {!!categoryData.availableTimeSlots?.length && hasDateRelatedFilters && (
          <Flex width="full">
            <VehicleCategoryTimeslotsTags
              timezone={categoryData.branch.timeZoneId!}
              timeslots={categoryData.availableTimeSlots}
            />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default VehicleCategoryResultsCard;
