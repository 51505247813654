import React, { FC } from 'react';
import {
  FormSelectComponent,
  Autocomplete,
  FormFieldAttributes,
  Text,
} from '@wunder/ui-components';
import { t } from '@lingui/core/macro';

interface AddressSelectT {
  name?: string;
  fieldProps?: Partial<FormSelectComponent<Autocomplete>['fieldProps']>;
  fieldAttr?: Partial<FormFieldAttributes>;
  // Will be needed when we add geomapping
  // eslint-disable-next-line react/no-unused-prop-types
  additionalMapping?: Record<string, string>;
}

// TODO: This is currently a Text field but later will be converted to geomaping of real addresses
const AddressSelect: FC<AddressSelectT> = ({
  name = 'street',
  fieldProps = { label: t({ id: 'geomap.addressSelect.label', message: 'Address' }) },
  fieldAttr = { placeholder: t({ id: 'geomap.addressSelect.placeholder', message: 'Address' }) },
}) => {
  return <Text fieldAttr={{ id: 'street', ...fieldAttr }} name={name} fieldProps={fieldProps} />;
};

export default AddressSelect;
