import React, { useMemo } from 'react';
import AccountLayout from '@/features/account/components/Edit/Layout';
import { FormikProvider, useFormik, Form } from 'formik';
import { useNavigate } from 'react-router';
import { useRequestUserUpdateMutation } from '@/features/account/services/Account.service';
import Newsletter from '@/features/account/components/Edit/Newsletter';
import { Button } from '@wunder/ui-components';
import { Trans } from '@lingui/react/macro';
import { booking } from '@/core/pages/Booking/routes';
import { set, values } from 'lodash';
import { useTypedSelector } from '@/core/redux/hooks';
import { getMeData } from '@/features/account/redux/account.selectors';
import Page404 from '@/core/pages/Page404';
import { useRequestWhitelabelQuery } from '@/core/services/WhiteLabeling.service';

const AccountEditNewsletter = () => {
  const navigate = useNavigate();
  const [updateUser] = useRequestUserUpdateMutation();
  const { data: whiteLabeling } = useRequestWhitelabelQuery();
  const meData = useTypedSelector(getMeData);

  const hasAnyNewsLetter = useMemo(() => {
    if (!whiteLabeling?.newsletters) {
      return false;
    }

    return values(whiteLabeling?.newsletters).some((newsLetter) => newsLetter.enabled);
  }, [whiteLabeling]);

  const form = useFormik({
    initialValues: {
      newsletterOptions: meData!.newsletterOptions,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  if (!hasAnyNewsLetter) {
    return <Page404 />;
  }

  return (
    <AccountLayout
      actions={
        <Button onClick={() => navigate(booking.path)} variation="secondary">
          <Trans id="general.close">Close</Trans>
        </Button>
      }
    >
      <FormikProvider value={form}>
        <Form>
          <Newsletter
            newsLetters={whiteLabeling!.newsletters}
            onChange={(key, value) => {
              updateUser(
                set({ newsletterOptions: { ...(meData!.newsletterOptions ?? {}) } }, key, value),
              );
            }}
          />
        </Form>
      </FormikProvider>
    </AccountLayout>
  );
};

export default AccountEditNewsletter;
