import React, { FC } from 'react';
import { useLazyRequestCityGeocodingQuery } from '@/features/geomap/services/Map.service';
import { AutocompleteSelect, FormSelectComponent, Autocomplete } from '@wunder/ui-components';
import { t } from '@lingui/core/macro';
import { FormSelectOptionsAsArray } from '@wunder/ui-components/dist/lib/components/FormElements/Select/Select.types';
import { useFormikContext } from 'formik';

interface CitySelectT {
  name?: string;
  fieldProps?: Partial<FormSelectComponent<Autocomplete>['fieldProps']>;
  fieldAttr?: Partial<FormSelectComponent<Autocomplete>['fieldAttr']>;
  additionalMapping?: Record<string, string>;
  readonly?: boolean;
}

const CitySelect: FC<CitySelectT> = ({
  name = 'city',
  fieldProps = { label: t({ id: 'geomap.citySelect.label', message: 'City' }), clearable: true },
  fieldAttr = { placeholder: t({ id: 'geomap.citySelect.placeholder', message: 'City' }) },
  additionalMapping = {},
  readonly,
}) => {
  const { setFieldValue } = useFormikContext();
  const [requestCities] = useLazyRequestCityGeocodingQuery();

  const onChange = (value: FormSelectOptionsAsArray) => {
    void setFieldValue(name, value.additional?.cityAlone);

    if (fieldProps?.onChange) {
      fieldProps?.onChange(value);
    }
  };

  return (
    <AutocompleteSelect
      name={name}
      fieldAttr={{ id: 'city', ...fieldAttr, readonly }}
      fieldProps={{
        label: '',
        minSearchCharacters: 2,
        onChange: (value) => onChange(value as FormSelectOptionsAsArray),
        options: {
          getData: (query) => requestCities(query).unwrap(),
          mapData: {
            value: 'place_name',
            label: 'place_name',
            additional: { ...additionalMapping, cityAlone: 'text' },
          },
        },
        ...fieldProps,
      }}
    />
  );
};

export default CitySelect;
