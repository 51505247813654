import { FC, ReactNode } from 'react';
import { Column, Heading, Paragraph, Row } from '@wunder/ui-components';
import styles from '@/core/assets/scss/components/EditFormSection.module.scss';

export interface EditFormSectionT {
  title: ReactNode;
  description?: ReactNode;
  content: ReactNode;
}

const EditFormSection: FC<EditFormSectionT> = ({ title, description, content }) => (
  <Row className={styles.section} marginBottom={8} gapMd="md" justify="space-between">
    <Column sm={12} md={5}>
      <Heading size={5} className={styles.section__title}>
        {title}
      </Heading>
      {description && (
        <Paragraph size={4} className={styles.section__description}>
          {description}
        </Paragraph>
      )}
    </Column>

    <Column sm={12} md={6}>
      {content}
    </Column>
  </Row>
);

export default EditFormSection;
