import React, { FC, lazy, Suspense, useState } from 'react';
import { useVerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import { useRequestVerificationSessionDataQuery } from '@/features/account/services/Verification.service';
import { Loader, Row } from '@wunder/ui-components';
import {
  VerificationMessage,
  VerificationMessageSize,
} from '@/features/account/components/Verification/VerificationMessage';
import styles from '@/features/account/assets/Verification/AccountVerification.module.scss';
import { useResponsive } from '@/core/hooks/useResponsive';

const verificationIntegrations = {
  Veriff: lazy(() => import('./VeriffIntegration')),
  Onfido: lazy(() => import('./OnfidoIntegration')),
};

interface AccountVerificationT {
  verificationMessageSize?: VerificationMessageSize;
}

const AccountVerification: FC<AccountVerificationT> = ({ verificationMessageSize }) => {
  const verificationStatus = useVerificationStatus();
  const [verifyUserRequired, setVerifyUserRequired] = useState(
    verificationStatus === 'uninitialized',
  );
  const { isMobile } = useResponsive();

  const { data: verificationSession, isFetching: isFetchingSessionData } =
    useRequestVerificationSessionDataQuery(undefined, {
      skip: !verifyUserRequired,
    });

  if (isFetchingSessionData) {
    return <Loader cover />;
  }

  if (!verifyUserRequired) {
    return (
      <VerificationMessage
        size={verificationMessageSize}
        verificationStatus={verificationStatus}
        onTryAgain={() => setVerifyUserRequired(true)}
      />
    );
  }

  return (
    <Row className={styles['account-verification']}>
      {verificationSession?.type === 'VERIFF_SDK' && (
        <Suspense fallback={<Loader />}>
          <verificationIntegrations.Veriff />
        </Suspense>
      )}

      {verificationSession?.type === 'ONFIDO_SDK' && (
        <Suspense fallback={<Loader />}>
          <verificationIntegrations.Onfido
            onVerificationComplete={() => {
              if (isMobile) {
                setVerifyUserRequired(false);
              }
            }}
          />
        </Suspense>
      )}
    </Row>
  );
};

export default AccountVerification;
