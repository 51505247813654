import React, { FC, useEffect } from 'react';
import { Modal, NotificationStore } from '@wunder/ui-components';
import { t } from '@lingui/core/macro';
import { setStepComplete } from '@/features/account/redux/account.reducer';
import { AccountService } from '@/features/account/services/Account.service';
import { AccountCacheTags } from '@/features/account/enums';
import { useTypedDispatch } from '@/core/redux/hooks';

interface StripePaymentHooksT {
  stripeSdk: string | null;
  clearStripeSdk: () => void;
}

const StripePaymentHooks: FC<StripePaymentHooksT> = ({ stripeSdk, clearStripeSdk }) => {
  const dispatch = useTypedDispatch();

  const getStripeErrorMessage = ({
    decline_code,
    message,
  }: {
    decline_code: string;
    message: string; // Message in English returned by Stripe
  }) => {
    switch (decline_code) {
      case 'insufficient_funds':
        return t({
          id: 'account.payment.insufficientFunds',
          message: 'Your card has insufficient funds.',
        });
      default:
        return message;
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === 'https://hooks.stripe.com') {
        if (event.data?.message?.error) {
          NotificationStore.addNotification({
            type: 'error',
            title: t({ id: 'account.payment.notificationTitle', message: 'Payment failed' }),
            content: getStripeErrorMessage(event.data.message.error),
          });
        } else {
          dispatch(setStepComplete({ name: 'payment', isComplete: true }));
          dispatch(AccountService.util.invalidateTags([AccountCacheTags.REQUEST_ME_DATA]));
        }
        clearStripeSdk();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (stripeSdk) {
    return (
      <Modal opened onClose={clearStripeSdk}>
        <iframe
          style={{ border: 'none', height: '780px' }}
          src={stripeSdk}
          title="StripeSdkJs"
          width="100%"
          height="100%"
        />
      </Modal>
    );
  }

  return null;
};

export default StripePaymentHooks;
